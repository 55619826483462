import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTransports,
  updateTransport,
} from "../../../redux/slices/transportSlice";
import { ImSpinner2 } from "react-icons/im";

export default function ModalUpdateTransport({ id, country, name, address, city, state, phone}) {
  const [showModal, setShowModal] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setUpdatedData({ country, name, address, city, state, phone});
  }, [country, name, address, city, state, phone]);

  const status = useSelector((state) => state.transport?.status);

  const countries = useSelector((state) => state.country?.countries);
  const states = useSelector((state) => state.state?.states);
  const provinces = useSelector((state) => state.province?.provinces);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUpdatedData({
      ...updatedData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(updateTransport({ id, updatedData }));

      await  dispatch(fetchTransports());
      toast.success(res.payload.message);
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <FaEdit className="w-4 h-4 text-[#667085]" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-6 ">
                    <h1 className="text-lg text-gray-800 text-center font-medium mb-4">
                      Update Transportation
                    </h1>

                    <div className="flex gap-2">
                      <div className=" flex flex-row gap-8">
                        <div className=" flex flex-col gap-4">
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="name"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              Name
                            </label>
                            <div className="flex items-center">
                              <input
                                name="name"
                                id="name"
                                type="text"
                                value={updatedData.name}
                                onChange={handleInputChange}
                                placeholder="Type transport name here..."
                                className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="phone"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              Phone
                            </label>
                            <div className="flex items-center">
                              <input
                                phone="phone"
                                id="phone"
                                type="tel"
                                value={updatedData.phone}
                                onChange={handleInputChange}
                                placeholder="Type phone here..."
                                className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="address"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              Address
                            </label>
                            <div className="flex items-center">
                              <input
                                name="address"
                                id="address"
                                type="text"
                                value={updatedData.address}
                                onChange={handleInputChange}
                                placeholder="Type address here..."
                                className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="country"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              Country
                            </label>
                            <div className="flex items-center">
                              <select
                                name="country"
                                id="country"
                                value={updatedData.country}
                                onChange={handleInputChange}
                                className="pl-[12px] p-2 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              >
                                <option value="">Select Country</option>
                                {countries?.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="state"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              State
                            </label>
                            <div className="flex items-center">
                              <select
                                name="state"
                                id="state"
                                value={updatedData.state}
                                onChange={handleInputChange}
                                className="pl-[12px] p-2 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              >
                                <option className="text-[#333843]">
                                  Select State
                                </option>
                                {states
                                  ?.filter(
                                    (state) =>
                                      state.country_id === updatedData.country
                                  )
                                  .map((state) => (
                                    <option key={state.id} value={state.id}>
                                      {state.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="city"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              City
                            </label>
                            <div className="flex items-center">
                              <select
                                name="city"
                                id="city"
                                value={updatedData.city}
                                onChange={handleInputChange}
                                className="pl-[12px] p-2 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              >
                                <option className="text-[#333843]">
                                  Select City
                                </option>
                                {provinces
                                  ?.filter(
                                    (province) =>
                                      province.country_id ===
                                        updatedData.country &&
                                      province.state_id === updatedData.state
                                  )
                                  .map((province) => (
                                    <option
                                      key={province.id}
                                      value={province.id}
                                    >
                                      {province.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleSubmit}
                      >
                        {status === "loading" ? (
                          <>
                            <ImSpinner2 className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Updating ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaEdit className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Update Transportation
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
