import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPlus } from "react-icons/fa";
import { HiChevronRight } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import PasswordInput from "../../components/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { addAdmin } from "../../redux/slices/adminSlice";
import { CgSpinner } from "react-icons/cg";

const NewAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialFormData = {
    name: "",
    email: "",
    country: "",
    state: "",
    province: "",
    phone: "",
    role: "",
    all: null, 
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));
  const roles = useSelector((state) => state.role?.roles);

  const status = useSelector((state) => state.admin?.status);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "all" ? Number(value) : value, // Convert 'all' to number
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(formData)
    try {
      const res = await dispatch(addAdmin(formData));
      toast.success(res.payload.message);
      setFormData({ ...initialFormData });
      navigate("/dashboard/admin");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Create Admin
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>
            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/admin">
              <p className="text-[14px] text-[#1F7BF4] font-medium">Admin</p>
            </Link>
            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500 font-medium">
              Create Admin
            </p>
          </div>
        </div>
        <div className=" flex items-end">
          <button
            type="button"
            onClick={handleSubmit}
            className="flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium"
          >
            {status === "loading" ? (
              <>
                <CgSpinner className="animate-spin text-lg " />
                Saving...
              </>
            ) : (
              <>
                <FaPlus className=" text-lg font-extrabold" />
                Save Admin
              </>
            )}
          </button>
        </div>
      </div>
      <div className="flex flex-row  mt-6 gap-6">
        <div className="w-full h-auto">
          <form>
            <div className="w-full h-auto flex flex-col px-6 py-6 bg-white rounded-lg shadow gap-[14px]">
              <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                General Information
              </h1>
              <div className="flex flex-row gap-4 ">
                <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
                  <label
                    htmlFor="name"
                    className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                  >
                    Full Name
                  </label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Type fullname here..."
                      className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    />
                  </div>
                </div>
                <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
                  <label
                    htmlFor="email"
                    className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                  >
                    Email
                  </label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Type email here..."
                      className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-4 ">
                <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
                  <label
                    htmlFor="phone"
                    className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                  >
                    Phone
                  </label>
                  <div className="flex items-center w-full">
                    <input
                      type="number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Enter phone number here..."
                      className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    />
                  </div>
                </div>
                <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
                  <label
                    htmlFor="password"
                    className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                  >
                    Password
                  </label>
                  <div className="flex items-center w-full">
                    <PasswordInput
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      placeholder="Password"
                      className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    />
                  </div>
                </div>

                <div className="flex flex-col mt-4 w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="role"
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      Role
                    </label>
                    <select
                      name="role"
                      id="role"
                      value={formData.role}
                      onChange={handleInputChange}
                      className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    >
                      <option value="" className="text-gray-500" disabled>
                        Select Role
                      </option>
                      {roles?.map((role) => (
                        <option key={role.id} value={role.name}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex flex-row gap-4">
                <div className="flex flex-col mt-4 w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="all"
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                        Select All Location
                    </label>
                    <div className="flex items-center">
                      <select
                        name="all"
                        id="all"
                        value={formData.all === null ? "" : formData.all}
                        onChange={handleInputChange}
                        className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      >
                        <option value="" className="text-gray-500" disabled>
                          Select Yes or No
                        </option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-4 w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="countries"
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      Country
                    </label>
                    <select
                      name="country"
                      id="country"
                      value={formData.country}
                      onChange={handleInputChange}
                      className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    >
                      <option className="text-[#333843]">Select Country</option>
                      {countries?.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex flex-col mt-4 w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="states"
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      State
                    </label>
                    <select
                      name="state"
                      id="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    >
                      <option className="text-[#333843]">Select State</option>
                      {states
                        ?.filter(
                          (state) => state.country_id === formData.country
                        )
                        .map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="flex flex-col mt-4 w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="provinces"
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      Province
                    </label>
                    <select
                      name="province"
                      id="province"
                      value={formData.province}
                      onChange={handleInputChange}
                      className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    >
                      <option className="text-[#333843]">Select City</option>
                      {provinces
                        ?.filter(
                          (province) =>
                            province.country_id === formData.country &&
                            province.state_id === formData.state
                        )
                        .map((province) => (
                          <option key={province.id} value={province.id}>
                            {province.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewAdmin;
