import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { PiPlusBold } from "react-icons/pi";
import {
  addLaundryStore,
  fetchLaundryStore,
} from "../../../redux/slices/laundryStoreSlice";

export default function ModalAddLaundryStore() {
  const [showModal, setShowModal] = React.useState(false);

  const dispatch = useDispatch();

  const status = useSelector((state) => state.laundryStore?.status);
  const countries = useSelector((state) => state.country?.countries);
  const states = useSelector((state) => state.state?.states);
  const provinces = useSelector((state) => state.province?.provinces);

  const initialFormData = {
    name: "",
    country: "",
    state: "",
    city: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await dispatch(addLaundryStore(formData));

      await dispatch(fetchLaundryStore());
      toast.success(res.payload.message);
      setFormData({ ...initialFormData });
    } catch (error) {
      console.error(error);
    }
    setShowModal(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium"
      >
        <PiPlusBold className=" text-lg font-extrabold" />
        Add Laundry Store
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-8 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-5 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Add A Laundry Store
                  </h1>
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-6 ">
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="category_name"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Name
                      </label>
                      <div className="flex items-center">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          value={formData.name}
                          onChange={handleInputChange}
                          placeholder="Type laundry store here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="category_name"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Name of Country
                      </label>
                      <div className="flex items-center">
                        <select
                          name="country"
                          id="country"
                          value={formData.country}
                          onChange={handleInputChange}
                          className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        >
                          <option className="text-[#333843]">
                            Select Country
                          </option>
                          {countries?.map((country) => (
                            <option key={country.id} value={country.id}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="state"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Name of State
                      </label>
                      <div className="flex items-center">
                        <select
                          name="state"
                          id="state"
                          value={formData.state}
                          onChange={handleInputChange}
                          className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        >
                           <option className="text-[#333843]">
                            Select Country
                          </option>
                          {states
                            ?.filter(
                              (state) => state.country_id === formData.country
                            )
                            .map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="state"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Name of City
                      </label>
                      <div className="flex items-center">
                        <select
                          name="city"
                          id="city"
                          value={formData.city}
                          onChange={handleInputChange}
                          className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        >
                          <option className="text-[#333843]">
                            Select City
                          </option>
                          {provinces
                            ?.filter(
                              (province) =>
                                province.country_id === formData.country &&
                                province.state_id === formData.state
                            )
                            .map((province) => (
                              <option key={province.id} value={province.id}>
                                {province.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="flex gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleSubmit}
                      >
                        {status === "loading" ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Adding ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaTimes className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Add Laundry Store
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
