import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchDrivers = createAsyncThunk(
  "driver/fetchDrivers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get("/admin/drivers");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDriver = createAsyncThunk(
  "driver/updateDriver",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/driver/update", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyDriver = createAsyncThunk(
  "driver/verifyDriver",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/driver/verify", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const suspendDriver = createAsyncThunk(
  "driver/suspendDriver",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/driver/suspend",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDriverDetail = createAsyncThunk(
  "driver/fetchDriverDetail ",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.get(`/admin/driver/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteDriver = createAsyncThunk(
  "driver/deleteDriver",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiService.delete(`/admin/delete-driver/${id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const driverSlice = createSlice({
  name: "driver",
  initialState: {
    drivers: [],
    status: "idle",
    error: null,
    driverDetail: null,
    driverDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDrivers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchDrivers.fulfilled, (state, action) => {
        state.status = "success";
        state.drivers = action.payload.data;
      })
      .addCase(fetchDrivers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateDriver.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDriver.fulfilled, (state, action) => {
        state.status = "success";
        state.drivers = state.drivers?.map((dr) =>
          dr.id === action.payload.id ? action.payload : dr
        );
      })
      .addCase(updateDriver.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload.message;
      })
      .addCase(verifyDriver.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyDriver.fulfilled, (state, action) => {
        state.status = "success";
        state.drivers = state.drivers?.map((dr) =>
          dr.id === action.payload.id ? action.payload : dr
        );
      })
      .addCase(verifyDriver.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload.message;
      })
      .addCase(suspendDriver.pending, (state) => {
        state.status = "loading";
      })
      .addCase(suspendDriver.fulfilled, (state, action) => {
        state.status = "success";
        state.drivers = state.drivers?.map((dr) =>
          dr.id === action.payload.id ? action.payload : dr
        );
      })
      .addCase(suspendDriver.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload.message;
      })
      .addCase(fetchDriverDetail.pending, (state) => {
        state.driverDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchDriverDetail.fulfilled, (state, action) => {
        state.driverDetailStatus = "succeeded";
        state.driverDetail = action.payload;
      })
      .addCase(fetchDriverDetail.rejected, (state, action) => {
        state.driverDetailStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteDriver.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteDriver.fulfilled, (state, action) => {
        state.status = "success";
        state.drivers = state.drivers?.filter(driver => driver.id !== action.payload);
      })
      .addCase(deleteDriver.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

export const selectDriverDetail = (state) => state.driver?.driverDetail;

export default driverSlice.reducer;
