import React, { useState } from "react";
import { toast } from "react-toastify";
import { FaPlus, FaTrash } from "react-icons/fa";
import { HiChevronRight } from "react-icons/hi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { updateRole } from "../../redux/slices/rolesSlice";
import { useDispatch, useSelector } from "react-redux";
import { ImSpinner2 } from "react-icons/im";

const UpdateAdminRoles = () => {
  const location = useLocation();
  const { role } = location.state || {};
  const [name, setName] = useState(role?.name || "");
  const [roleFunctions, setRoleFunctions] = useState(role?.role || []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const id = role.id;

  const status = useSelector((state) => state.role?.status);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const newRoles = roleFunctions.map((role, i) =>
      i === index ? value : role
    );

    setRoleFunctions(newRoles);
  };

  const handleAddRole = () => {
    setRoleFunctions([...roleFunctions, ""]);
  };

  const handleRemoveRole = (index) => {
    const newRoles = roleFunctions.filter((_, i) => i !== index);
    setRoleFunctions(newRoles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedRole = { name, role: roleFunctions };
      console.log(updatedRole);
      const res = await dispatch(updateRole({ id, updatedRole }));

      await navigate("/dashboard/admin/admin-roles");
      
      toast.success(res.payload.message);
      toast.error(res.payload.message);
    } catch (error) {
      console.error(error);
      toast.error("Failed to update roles");
    }
  };

  const isAddRoleButtonDisabled =
    roleFunctions?.length === 0 || roleFunctions?.includes("");
    
    const isSaveButtonDisabled =
    name === "" || (Array.isArray(roleFunctions) ? roleFunctions?.some((role) => role === "") : false);

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Update Admin Roles
          </h1>
          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>
            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/admin/admin-roles">
              <p className="text-[14px] text-[#1F7BF4] font-medium">
                Admin Role
              </p>
            </Link>
            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500 font-medium">
              Update Admin Role
            </p>
          </div>
        </div>
        <div className="flex items-end">
          <button
            type="button"
            onClick={handleSubmit}
            className={`flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium transition-all duration-200 ${
              isSaveButtonDisabled || status === "loading"
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-[#1663cc]"
            }`}
            disabled={isSaveButtonDisabled || status === "loading"}
          >
            {status === "loading" ? (
              <>
                <ImSpinner2 className="animate-spin" />
                <p className="text-sm font-semibold whitespace-nowrap">
                  Saving ...
                </p>
              </>
            ) : (
              <>
                <p className="text-sm font-semibold whitespace-nowrap">
                  Save Admin Roles
                </p>
              </>
            )}
          </button>
        </div>
      </div>
      <div className="flex flex-row mt-6 gap-6">
        <div className="w-full h-auto">
          <form>
            <div className="w-full h-auto flex flex-col px-6 py-6 bg-white rounded-lg shadow gap-[14px]">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
                  <label
                    htmlFor="name"
                    className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                  >
                    Name
                  </label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Type name here. . ."
                      className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    />
                  </div>
                </div>
                {roleFunctions?.map((role, index) => (
                  <div
                    key={index}
                    className="flex flex-col mt-4 gap-[14px] w-full h-auto px-4 py-4 bg-white rounded-lg shadow"
                  >
                    <label
                      htmlFor={`role-${index}`}
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      Role {index + 1}
                    </label>
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        name={`role-${index}`}
                        value={role}
                        onChange={(e) => handleInputChange(e, index)}
                        placeholder="Type role here. . ."
                        className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveRole(index)}
                        className="flex gap-[8px] bg-red-500 text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium transition-all duration-200 hover:bg-red-600"
                      >
                        <FaTrash className="text-lg font-extrabold" />
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddRole}
                  className={`flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium mt-4 transition-all duration-200 ${
                    isAddRoleButtonDisabled
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-[#1663cc]"
                  }`}
                  disabled={isAddRoleButtonDisabled}
                >
                  <FaPlus className="text-lg font-extrabold" />
                  Add Role
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateAdminRoles;
