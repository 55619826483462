import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { fetchWithdrawal } from "../../redux/slices/withdrawalSlice";
import { ImSpinner2 } from "react-icons/im";
import { formatCreatedAt, formatNumberWithCommas } from "../../utils/commonUtils";
import ApproveShopperWithdrawal from "../../components/modals/withdrawal/ApproveShopperWithdrawal";

const ShopperWithdrawal = () => {
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWithdrawal());
  }, [dispatch]);

  const withdrawals = useSelector((state) => state.withdrawal?.withdrawals);
  const status = useSelector((state) => state.withdrawal?.status);

  const shopperWithdrawals = withdrawals?.filter(
    (withdrawal) => withdrawal?.type === "shopper"
  );


  const error = useSelector((state) => state.withdrawal?.error);


  const handlePageClick = (page) => {
    setActivePage(page);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Shopper Withdrawals
          </h1>

          <div className="flex mb-4 justify-start items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">Shopper</p>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Shopper Withdrawals
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-4 text-center">No.</th>
                <th className="px-4 py-4 text-center">Name</th>
                <th className="px-4 py-4 text-left">Account</th>
                <th className="px-4 py-4 text-center">Amount</th>
                {/* <th className="px-2 py-3 text-center">Sent</th> */}
                <th className="px-4 py-4 text-center">Created</th>
                <th className="px-4 py-4 text-center">Updated</th>
                <th className="px-4 py-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm text-left">
            {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : shopperWithdrawals?.length === 0 ? (
                <tr>
                  <td
                    colSpan="12"
                    className="text-center text-base text-gray-500 py-4"
                  >
                    No Data
                  </td>
                </tr>
              ) : (
                shopperWithdrawals
                  ?.filter((user) => {
                    return search.toLowerCase() === ""
                      ? user
                      : user.name.toLowerCase().includes(search);
                  })
                  ?.map((user, index) => (
                    <tr key={user.id}>
                      <td className="px-4 py-4 ">
                        <div className="flex justify-center items-center">
                          <p className=" text-base text-center font-semibold ">
                            {index + 1}
                          </p>
                        </div>
                      </td>
                      <td className="px-4 py-4">
                        <div className="flex flex-col text-center gap-2">
                          <p className=" text-blue-600 text-base font-semibold">
                            {user.name}
                          </p>
                        </div>
                      </td>
                      <td className="px-4 py-4 text-left">
                        <div className=" flex flex-col gap-2">
                          <p>{user.account.bank_name}</p>
                          <p>{user.account.account_number}</p>
                        </div>
                      </td>
                      <td className="px-4 py-4 text-center">
                        <span className="mr-1">&#8358;</span>{formatNumberWithCommas(user.amount)}
                      </td>
                      {/* <td className="px-4 py-4 text-center">
                        {user.sent ? user.sent : "No Data"}
                      </td> */}

                      <td className="px-4 py-4 text-center whitespace-nowrap">
                        {formatCreatedAt(user.created_at)}
                      </td>
                      <td className="px-4 py-4 text-center whitespace-nowrap">
                        {formatCreatedAt(user.updated_at)}
                      </td>
                      <td className="px-4 py-4">
                        <div className="flex gap-4 justify-center items-center">
                          <div className="">
                            <ApproveShopperWithdrawal
                              id={user.id}
                              name={user.name}
                              shopper_id={user.shopper_id}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing 1 - 1 from 1
          </p>

          <div className="flex gap-[8px]">
            <button
              className="bg-[#DEDEFA] w-[32px] h-[32px] flex items-center justify-center rounded-[8px] hover:bg-[#5C59E8] text-[#5C59E8] hover:text-white"
              onClick={() =>
                handlePageClick(activePage > 1 ? activePage - 1 : 1)
              }
            >
              <IoChevronBack className="w-4 h-4" />
            </button>

            {[1].map((page) => (
              <button
                key={page}
                type="button"
                className={`${
                  page === activePage
                    ? "bg-[#5C59E8] text-white"
                    : "bg-[#DEDEFA] text-[#5C59E8]"
                } w-[32px] h-[32px] rounded-[8px] flex items-center justify-center hover:bg-[#5C59E8] hover:text-white`}
                onClick={() => handlePageClick(page)}
              >
                {page}
              </button>
            ))}

            <button
              className="bg-[#DEDEFA] w-[32px] h-[32px] rounded-[8px] flex items-center justify-center text-[#5C59E8] hover:bg-[#5C59E8] hover:text-white"
              onClick={() => handlePageClick(activePage + 1)}
            >
              <IoChevronForward className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopperWithdrawal;
