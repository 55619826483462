import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";


export const fetchLaundryPending = createAsyncThunk(
  "laundryPending/fetchLaundryPending",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get('/pending', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });


      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const cancelLaundry = createAsyncThunk(
  "laundryPending/cancelLaundry",
  async (orderId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(`/cancel/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const laundryPendingSlice = createSlice({
  name: "laundryPending",
  initialState: {
    laundryPendings: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLaundryPending.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchLaundryPending.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laundryPendings = action.payload.data;
      })
      .addCase(fetchLaundryPending.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(cancelLaundry.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(cancelLaundry.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(cancelLaundry.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });
  },
});

export default laundryPendingSlice.reducer;
