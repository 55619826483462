import axios from "axios";

const BASE_URL = "https://live.capfieldsevolve.com/api/v1";
// const BASE_URL = "https://appi.capfieldsevolve.com/api/v1";

const token = localStorage.getItem("token");

export const apiService = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${token}`
  },
});

export const loginAPI = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/admin/login`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error("Login request failed");
  }
};

// export const verifyOTPAPI = async (otpData) => {
//   try {
//     const response = await axios.post(
//       `${BASE_URL}/auth/admin/confirm/signin`,
//       otpData,
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     return response.data;
//   } catch (error) {
//     throw new Error("OTP verification request failed");
//   }
// };
