import React, { useState, useEffect } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchIsusuApprovalRequest } from "../../redux/slices/isusuSlice";
import { formatCreatedAt } from "../../utils/commonUtils";
import { ImSpinner2 } from "react-icons/im";
import ModalIsusuAprovalRequest from "../../components/modals/investment/ModalIsusuAprovalRequest";

const IsusuApprovalRequest = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIsusuApprovalRequest());
  }, [dispatch]);

  const isusus = useSelector((state) => state.isusu?.isusuApprovalRequests);

  const reversedIsusus = isusus.slice().reverse();

  // Filter categories based on search term
  const filteredIsusus = reversedIsusus.filter((order) =>
    search.toLowerCase() === ""
      ? order
      : order.name.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentIsusus = filteredIsusus.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredIsusus.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.isusu?.status);
  const error = useSelector((state) => state.isusu?.error);

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Isusu Approval Request
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Investment
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Isusu Approval Requests
            </p>
          </div>
        </div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search ..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="w-full whitespace-nowrap text-sm text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-3 text-left">Customer Name</th>
                <th className="px-4 py-3 text-center w-48">Address</th>

                <th className="px-4 py-3 text-center">Cheque</th>
                <th className="px-4 py-3 text-center">Salary Slip</th>
                <th className="px-4 py-3 text-center">Card Details</th>

                <th className="px-4 py-3 text-center">Created Date</th>
                <th className="px-4 py-3 text-center">Updated Date</th>

                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                      {/* <CgSpinner className="animate-spin h-8 w-8 text-blue-700" /> */}
                    </div>
                  </td>
                </tr>
              ) : currentIsusus.length === 0 ? (
                <tr>
                  <td
                    colSpan="12"
                    className="text-center text-base text-gray-500 py-4"
                  >
                    No Data
                  </td>
                </tr>
              ) : (
                currentIsusus?.map((order, index) => (
                  <tr key={index} className="bg-white border-b">
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>
                    <td className="px-4 py-4 font-medium whitespace-nowrap text-left">
                      <Link
                        to={`/dashboard/investment/isusu-details/${order.id}`}
                      >
                        <div className=" flex flex-col gap-1  text-gray-600">
                          <p className=" text-base font-semibold">
                            {`${order.firstname} ${order.firstname}`}
                          </p>
                          <p>{order.email}</p>
                          <p>{order.phone}</p>
                        </div>
                      </Link>
                    </td>

                    <td className="px-4 py-3 text-center">
                      <div className="flex flex-col gap-1">
                        <p className="px-4 py-1 text-[14px] font-medium whitespace-normal w-48 break-words ">
                          {order.address}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-3 text-center">
                      <div className="flex flex-col gap-1">
                        <p className="px-4 py-1 text-[14px]  font-semibold whitespace-nowrap ">
                          {order.cheque ? order.cheque : "No Info"}
                        </p>
                      </div>
                    </td>

                    <td className="px-4 py-3 text-center">
                      <div className="flex flex-col gap-1">
                        <img
                          src={order.salary_slip}
                          alt="cheque"
                          className="w-24 h-24 object-cover rounded-lg"
                        />
                      </div>
                    </td>

                    <td className="px-4 py-3 text-center">
                      <div className="flex flex-col gap-1">
                        <p className="px-4 py-1 text-[14px]  font-semibold whitespace-nowrap ">
                          {order.card_details ? order.card_details : "No Info"}
                        </p>
                      </div>
                    </td>

                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(order.created_at)}
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(order.updated_at)}
                    </td>

                    <td className="px-4 py-4">
                      <div className="flex gap-[8px] items-center justify-center">
                        <ModalIsusuAprovalRequest
                          id={order.id}
                          name={`${order.firstname} ${order.firstname}`}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {isusus.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsusuApprovalRequest;
