import React, {  useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiSave } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { HiChevronRight } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addStore } from "../../redux/slices/storeSlice";
import { CgSpinner } from "react-icons/cg";
import UploadImage from "../UploadImage";

const AddStore = () => {
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialFormData = {
    name: "",
    image: "",
    note: "",
    landmark: "",
    location: "",
    type: "",
    country: "",
    state: "",
    province: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });


  const countries = useSelector((state) => state.country?.countries);
  const states = useSelector((state) => state.state?.states);
  const provinces = useSelector((state) => state.province?.provinces);
  const status = useSelector((state) => state.store?.status);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUploadImage = (file) => {
    // Set the uploaded file in the form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(formData);

      const res = await dispatch(addStore(formData));

      if (res.payload.status === "success") {
        navigate("/dashboard/shopping/stores");
        const message = res.payload.message;
        toast.success(message);
        setFormData({ ...initialFormData });
      } else {
        toast.error("Server error occurred. Try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Add Store
          </h1>
          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Shopping</p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4] font-medium">Store</p>
            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-[#667085] font-medium">Add Store</p>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <Link to="/dashboard/shopping/stores">
            <button
              type="button"
              className="flex gap-2 text-[#84818A] border-2 items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-semibold"
            >
              <FaTimes className=" text-lg font-extrabold" />
              Cancel
            </button>
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex mt-6 gap-6">
          <div className="w-3/12 h-auto">
            <div className="w-full h-auto flex flex-col px-6 py-6 bg-white rounded-lg shadow gap-[14px]">
              <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                Thumbnail
              </h1>

              <div>
                <UploadImage onUpload={handleUploadImage} />
              </div>
              <textarea
                name="note"
                id="note"
                value={formData.note}
                onChange={handleInputChange}
                cols="5"
                rows="10"
                placeholder=" Type note here..."
                className=" border-2 rounded-lg p-4"
              ></textarea>
            </div>
          </div>
          <div className="w-9/12 h-auto">
            <div className="w-full h-auto flex flex-col px-6 py-6 bg-white rounded-lg shadow gap-[14px]">
              <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                General Information
              </h1>

              <div className="flex flex-col gap-1">
                <label
                  htmlFor="category_name"
                  className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                >
                  Store Name
                </label>
                <div className="flex items-center">
                  <input
                    id="name"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Type store name here..."
                    className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-4">
              <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                <div className="flex flex-col gap-[1px]">
                  <label
                    htmlFor="location"
                    className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                  >
                    Landmark
                  </label>
                  <input
                    type="text"
                    name="landmark"
                    id="landmark"
                    value={formData.landmark}
                    onChange={handleInputChange}
                    placeholder="Type landmark here..."
                    className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                <div className="flex flex-col gap-[1px]">
                  <label
                    htmlFor="location"
                    className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    name="location"
                    id="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    placeholder="Type location here..."
                    className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-4">
              <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                <div className="flex flex-col gap-[1px]">
                  <label
                    htmlFor="location"
                    className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                  >
                    Country
                  </label>
                  <select
                    name="country"
                    id="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                  >
                    <option className="text-[#333843]">Select Country</option>
                    {countries?.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                <div className="flex flex-col gap-[1px]">
                  <label
                    htmlFor="location"
                    className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                  >
                    State
                  </label>
                  <select
                    name="state"
                    id="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                  >
                    <option className="text-[#333843]">Select State</option>
                    {states
                      ?.filter((state) => state.country_id === formData.country)
                      .map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                <div className="flex flex-col gap-[1px]">
                  <label
                    htmlFor="location"
                    className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                  >
                    City
                  </label>
                  <select
                    name="province"
                    id="province"
                    value={formData.province}
                    onChange={handleInputChange}
                    className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                  >
                    <option className="text-[#333843]">Select City</option>
                    {provinces
                      ?.filter(
                        (province) =>
                          province.country_id === formData.country &&
                          province.state_id === formData.state
                      )
                      .map((province) => (
                        <option key={province.id} value={province.id}>
                          {province.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className=" flex justify-between items-end gap-4">
              <div className=" w-1/2">
                <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-[1px]">
                    <label
                      htmlFor="location"
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      Type
                    </label>
                    <select
                      name="type"
                      id="type"
                      value={formData.type}
                      onChange={handleInputChange}
                      className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    >
                      <option value="" className="text-[#333843]" disabled>
                        Choose type
                      </option>
                      <option value="trending">Trending</option>
                      <option value="general">General</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={status === "loading"}
                  className={`w-36 mt-4 flex justify-center gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium ${
                    status === "loading" ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {status === "loading" ? (
                    <>
                      <CgSpinner className="animate-spin text-lg text-blue-700" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <BiSave className="text-lg font-extrabold" />
                      Save Store
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddStore;
