// Header.js
import React from "react";
import { FaEnvelope, FaBell } from "react-icons/fa";
import LogoutModal from "../pages/auth/Logout";

const Header = () => {
  const randomImageNumber = Math.floor(Math.random() * 1000) + 1;

  return (
    <header className=" bg-white text-gray-800 p-2 flex items-center justify-between">
      <div className="flex items-center"></div>
      <div className="flex items-center justify-between mr-12">
      
        <div className="p-4 ">
          <FaBell className="text-xl text-gray-600" />
        </div>
        <div className="p-4">
          <FaEnvelope className="text-xl text-gray-500" />
        </div>
        <div className="p-4 ">
          <img
            src={`https://picsum.photos/40/40?random=${randomImageNumber}`}
            alt="User"
            className="w-10 h-10 rounded-full mr-2"
          />
          {/* <span className="text-base font-medium text"></span> */}
        </div>
        <div>
        <LogoutModal />
        </div>
      </div>
    </header>
  );
};

export default Header;
