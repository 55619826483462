import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { BiSlider } from "react-icons/bi";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";

import { useDispatch, useSelector } from "react-redux";

import { fetchProducts } from "../../redux/slices/productSlice";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";

import { ImSpinner2 } from "react-icons/im";

import DeleteProduct from "../../components/modals/product/DeleteProduct";

const ProductsList = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const products = useSelector((state) => state.product?.products);

  const reversedProducts = products.slice().reverse();

  const status = useSelector((state) => state.product?.status);
  const error = useSelector((state) => state.product?.error);

  // Filter categories based on search term
  const filteredProducts = reversedProducts.filter(
    (product) =>
      search.trim() === "" ||
      product.name.toLowerCase().includes(search.toLowerCase()) ||
      product.owner_type.toLowerCase().includes(search.toLowerCase())
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentProducts = filteredProducts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            All Products
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Shopping</p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">Products</p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search products..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>

        {/* <div className="flex items-center justify-center rounded gap-4">
          <button
            data-dropdown-toggle="dropdownFilter"
            id="dropdownFilterButton"
            className="flex items-center text-base font-medium text-[#667085] gap-[8px] rounded-md ring-2 ring-[#E0E2E7] px-3 py-2 bg-white"
            type="button"
          >
            <BiSlider className="text-gray-500 text-xl" />
            Filters
          </button>
        </div> */}
      </div>
      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left whitespace-nowrap text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-4 text-center">No.</th>
                <th className="px-12 py-4 text-center">Image</th>
                <th className="px-4 py-4 text-center">Name</th>
                <th className="px-4 py-4 text-center w-48">Description</th>
                <th className="px-4 py-4 text-center">Category</th>
                <th className="px-4 py-4 text-center ">Store</th>
                <th className="px-4 py-4 text-center ">Type</th>
                <th className="px-4 py-4 text-center">Price</th>
                <th className="px-4 py-4 text-center">Discount (%)</th>
                <th className="px-4 py-4 text-center">Discount Price</th>
                <th className="px-4 py-4 text-center">Created</th>
                <th className="px-4 py-4 text-center">Updated</th>
                <th className="px-4 py-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentProducts.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentProducts?.map((product, index) => (
                  <tr key={product._id} className="bg-white border-b">
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        <p className=" text-base text-center font-semibold ">
                          {indexOfFirstItem + index + 1}
                        </p>
                      </div>
                    </td>
                    <td className="px-2 py-2  whitespace-nowrap ">
                      <div className="flex justify-center p-2 items-center">
                        <img
                           src={
                            product.image && product.image.length > 0
                              ? product.image[0]
                              : "default-image-url.jpg"
                          }
                          alt={product.name}
                          className="w-24 h-24 object-cover rounded-lg"
                        />
                      </div>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <Link
                        to={`/dashboard/shopping/product-detail/${product.id}`}
                      >
                        <p className="text-sm text-gray-600 font-bold transition-all duration-300 hover:text-blue-600 hover:font-extrabold hover:text-base cursor-pointer">
                          {product.name}
                        </p>
                      </Link>
                    </td>
                   
                   
                    <td className="px-4  py-4 text-center">
                      <p className="text-[14px]  w-48 break-words whitespace-normal line-clamp-3">
                        {product?.description}
                      </p>
                    </td>

                    <td className="px-2 py-2 text-center">
                      <p>{product.category}</p>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <p>{product.owner_type}</p>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <p>{product.type ? product.type : "Not Status"}</p>
                    </td>
                    <td className="px-4 text-center">
                      <p className="text-[14px] text-[#667085] font-medium tracking-[0.07px]">
                        <span className="mr-1">&#8358;</span>{" "}
                        {formatNumberWithCommas(product.price)}
                      </p>
                    </td>
                    <td className="px-4 text-center">
                      <p className="text-[14px] text-[#667085] font-medium tracking-[0.07px]">
                        {product.discount ? product.discount : 0}
                      </p>
                    </td>
                    <td className="px-4 text-center">
                      <p className="text-[14px] text-[#667085] font-medium tracking-[0.07px]">
                        <span className="mr-1">&#8358;</span>{" "}
                        {product.discount_price
                          ? formatNumberWithCommas(product.discount_price)
                          : 0}
                      </p>
                    </td>

                    <td className="px-4 py-4 text-center">
                      {formatCreatedAt(product.created_at)}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {formatCreatedAt(product.updated_at)}
                    </td>
                    <td className="px-2 py-2">
                      <div className="flex gap-4 justify-center">
                        <div>
                          <Link
                            to={`/dashboard/shopping/product-detail/${product.id}`}
                          >
                            <IoEyeOutline className="text-base font-bold text-[#667085]" />
                          </Link>
                        </div>

                        <div>
                          <DeleteProduct pId={product.id} name={product.name} />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {products.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsList;
