import React, { useState, useEffect } from "react";
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../../config/firestore";
import { FaTimes } from "react-icons/fa";
import { ImSpinner2 } from "react-icons/im";
import ImageFullScreen from "../../ImageFullScreen";

const ReceiptModal = ({ isOpen, onClose, orderId }) => {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      const fetchReceipts = async () => {
        try {
          const q = query(
            collection(db, "Shopping Receipts"),
            where("order_id", "===", orderId)
          );
          const querySnapshot = await getDocs(q);
          const receiptData = querySnapshot.docs.map((doc) => doc.data());
          setReceipts(receiptData);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching receipts:", error);
          setLoading(false);
        }
      };

      fetchReceipts();
    }
  }, [isOpen, orderId]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white rounded-lg shadow-lg max-w-lg w-full p-4">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-900"
        >
          <FaTimes />
        </button>
        <h2 className="text-xl p-4 text-center font-semibold mb-4">
          Receipt Images
        </h2>
        {loading ? (
          <div className="flex justify-center items-center">
            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
          </div>
        ) : (
          <div className="flex justify-center items-center gap-4">
            {receipts.length > 0 ? (
              receipts.map((receipt, receiptIndex) =>
                receipt.images.map((imageUrl, imageIndex) => (
                  <ImageFullScreen
                    // key={`${receiptIndex}-${imageIndex}`}
                    src={imageUrl}
                    alt={`Receipt ${receiptIndex + 1} - Images ${
                      imageIndex + 1
                    }`}
                  />
                ))
              )
            ) : (
              <p>No receipts found for this order.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceiptModal;
