import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { HiChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addWalletFee } from "../../redux/slices/feeSettingSlice";
import { formatNumberWithCommas2 } from "../../utils/commonUtils";

const TranscationFeeSetting = () => {
  const [formData, setFormData] = useState({
    amount: "",
    min_fee: "",
    max_fee: "",
  });

  const dispatch = useDispatch();

  const wallet_fee = useSelector(
    (state) => state.feeSetting?.feeSettings?.wallet_fee
  );

  useEffect(() => {
    if (wallet_fee) {
      setFormData({
        amount: wallet_fee.amount || "",
        min_fee: wallet_fee.min_fee || "",
        max_fee: wallet_fee.max_fee || "",
      });
    }
  }, [wallet_fee]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let adjustedValue = value;

    if (name === "amount") {
      const floatValue = parseFloat(value);
      // Ensure the value is between 0 and 100
      adjustedValue = Math.min(Math.max(floatValue, 1), 100).toString();
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: adjustedValue,
    }));
  };

  const status = useSelector((state) => state.feeSetting?.walletFeeStatus);

  const handleSubmit = async () => {
    if (!formData.amount || !formData.min_fee || !formData.max_fee) {
      toast.error("Please fill in all the required fields.");
      return;
    }
    try {
      await dispatch(addWalletFee(formData));
      toast.success("Wallet Transactions fee successfully set");
    } catch (error) {}
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Wallet Transaction Fee Setting
          </h1>

          <div className="flex mb-4 justify-start items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Fee Setting
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Wallet Transaction Fee Setting
            </p>
          </div>
        </div>

        <div className="flex items-center gap-4"></div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
          <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
            <div className="relative overflow-x-auto">
              <div className="mt-4">
                <div className=" bg-gray-50 text-center">
                  <p className="text-2xl font-bold">
                    Wallet Transactions Fee Setting
                  </p>
                </div>
                <div className="flex mt-4 items-center justify-center px-8 py-4">
                  <div className="flex flex-col gap-3 ">
                    <div className="flex flex-row justify-between items-center gap-4">
                      <label
                        htmlFor="amount"
                        className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px]"
                      >
                        Amount (%)
                      </label>
                      <div className="flex justify-end">
                        <input
                          name="amount"
                          id="name"
                          type="number"
                          value={formData.amount}
                          onChange={handleInputChange}
                          placeholder="Type here..."
                          className="pl-[12px] text-[#333843] text-right p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center gap-4">
                      <label
                        htmlFor="min_fee"
                        className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px]"
                      >
                        Minimun Fee
                      </label>
                      <div className="flex justify-end">
                        <input
                          name="min_fee"
                          id="name"
                          type="text"
                          value={formatNumberWithCommas2(formData.min_fee)}
                          onChange={handleInputChange}
                          placeholder="Type here..."
                          className="pl-[12px] text-[#333843] text-right p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center gap-4">
                      <label
                        htmlFor="max_fee"
                        className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px]"
                      >
                        Maximun Fee
                      </label>
                      <div className="flex justify-end">
                        <input
                          name="max_fee"
                          id="name"
                          type="text"
                          value={formatNumberWithCommas2(formData.max_fee)}
                          onChange={handleInputChange}
                          placeholder="Type here..."
                          className="pl-[12px] text-[#333843] text-right p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <button
                        className="flex gap-1 mt-4  rounded-lg items-center justify-center bg-blue-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleSubmit}
                      >
                        {status === "loading" ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Submitting ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FiPlus className=" font-bold text-base" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Submit
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranscationFeeSetting;
