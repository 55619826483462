import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";

import {
  fetchPharmacyDetail,
  selectPharmacyDetail,
} from "../../redux/slices/pharmacySlice";

import AddProductPharmacy from "../../components/modals/product/AddProductPharmacy";
// import AddCategoryPharmacy from "../../components/modals/product/AddCategoryPharmacy";
import { ImSpinner2 } from "react-icons/im";
import DeleteProductPharmacy from "../../components/modals/product/DeleteProductPharmacy";
import UpdateProductPharmacy from "../../components/modals/product/UpdateProductPharmacy";
import PharmacyProductDetail from "./PharmacyProductDetail";
import TransferProductPharmacy from "../../components/modals/product/TransferProductPharmacy";
import PharmacyDiscountPrice from "../../components/modals/product/PharmacyDiscountPrice";
import PharmacyIncrementPrice from "../../components/modals/product/PharmacyIncrementPrice";

const PharmacyDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const pharmacyDetail = useSelector(selectPharmacyDetail);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  useEffect(() => {
    dispatch(fetchPharmacyDetail(id));
  }, [dispatch, id]);

  const status = useSelector((state) => state.pharmacy?.storeDetailStatus);
  const error = useSelector((state) => state.pharmacy?.error);

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);

    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);

    const state = states.find((state) => state.id === parsedId);

    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };


  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (e, productId) => {
    const isChecked = e.target.checked;

    setSelectedProductIds((prevSelectedIds) => {
      if (isChecked) {
        // If checkbox is checked, add the productId to the selectedProductIds array
        return [...prevSelectedIds, productId];
      } else {
        // If checkbox is unchecked, remove the productId from the selectedProductIds array
        return prevSelectedIds.filter((id) => id !== productId);
      }
    });
  };

  const handleHeaderCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    setSelectAll(isChecked);

    if (isChecked) {
      const allUserIds = pharmacyDetail?.data?.products?.map(
        (product) => product.id
      );
      setSelectedProductIds(allUserIds);
    } else {
      setSelectedProductIds([]);
    }
  };

  const clearSelectedProductIds = () => {
    setSelectedProductIds([]);
    setSelectAll(false);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Pharmacy Details
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Store</p>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/shopping/pharmacy">
              <p className="text-[14px] text-[#1F7BF4] font-medium">Pharmacy</p>
            </Link>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Pharmacy Details
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
      ) : (
        <div>
          {pharmacyDetail ? (
            <div className="flex mt-8 gap-6">
              <div className="flex flex-col gap-6 w-4/12">
                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                      Pharmacy Information
                    </h1>

                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] whitespace-nowrap font-medium tracking-[0.07px]">
                            Name
                          </p>
                        </div>

                        <p className=" text-base font-semibold whitespace-nowrap">
                          {pharmacyDetail.data.pharmacy.name}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Landmark
                          </p>
                        </div>

                        <p className="text-base font-semibold whitespace-nowrap">
                          {pharmacyDetail.data.pharmacy.landmark}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Location
                          </p>
                        </div>

                        <p className="text-base font-semibold whitespace-nowrap">
                          {pharmacyDetail.data.pharmacy.location}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Country
                          </p>
                        </div>

                        <p className="text-base font-semibold whitespace-nowrap">
                          {getCountryNameById(
                            pharmacyDetail.data.pharmacy.country
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            State
                          </p>
                        </div>

                        <p className="text-base font-semibold whitespace-nowrap">
                          {getStateNameById(pharmacyDetail.data.pharmacy.state)}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            City
                          </p>
                        </div>
                        <p className="text-base font-semibold whitespace-nowrap">
                          {getCityNameById(
                            pharmacyDetail.data.pharmacy.province
                          )}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Status
                          </p>
                        </div>
                        <p className=" text-[#1A1C21] text-base font-semibold tracking-[0.07px]">
                          {pharmacyDetail.data.pharmacy.type}
                        </p>
                      </div>
                      <div className="flex flex-col gap-4">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Note
                          </p>
                        </div>
                        <textarea
                          name=""
                          id=""
                          cols="20"
                          rows="5"
                          value={pharmacyDetail.data.pharmacy.note}
                          className=" w-full text-[#1A1C21] text-base border-2 rounded-lg p-2 tracking-[0.07px]"
                        ></textarea>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Start Date
                          </p>
                        </div>
                        <p className=" text-[#1A1C21] text-base font-semibold tracking-[0.07px]">
                          {formatCreatedAt(
                            pharmacyDetail.data.pharmacy.created_at
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6 w-8/12">
                <div className=" flex flex-col  gap-6 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className=" flex justify-between">
                      <div>
                        <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                          Products
                        </h1>
                      </div>
                      <div className=" flex justify-between items-center gap-4">
                        <AddProductPharmacy
                          pId={id}
                          country={pharmacyDetail.data.pharmacy.country}
                          city={pharmacyDetail.data.pharmacy.province}
                          state={pharmacyDetail.data.pharmacy.state}
                        />
                        {pharmacyDetail.data.products.length === 0 ? (
                          ""
                        ) : (
                          <TransferProductPharmacy owner_id={id} />
                        )}
                         {pharmacyDetail.data.products.length === 0 ? (
                          ""
                        ) : (
                          <PharmacyDiscountPrice
                            pharmacyId={id}
                            clearSelectedProductIds={clearSelectedProductIds}
                            selectedProductIds={selectedProductIds}
                          />
                        )}

                        {pharmacyDetail.data.products.length === 0 ? (
                          ""
                        ) : (
                          <PharmacyIncrementPrice
                            pharmacyId={id}
                            clearSelectedProductIds={clearSelectedProductIds}
                            selectedProductIds={selectedProductIds}
                          />
                        )}
                      </div>
                    </div>
                    <div className="relative overflow-x-auto  p-4 rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                          <tr>
                            <th className="px-4 py-4 text-center">No.</th>
                            <th className="px-12 py-4 text-center">Image</th>
                            <th className="px-4 py-4 text-center">Name</th>

                            <th className="px-4 py-4 text-center">Category</th>
                            <th className="px-4 py-4 text-center">
                              Description
                            </th>
                            <th className="px-4 py-4 text-center ">Type</th>
                            <th className="px-4 py-4 text-center ">Price</th>

                            <th className="px-8 py-4 text-center">
                              Created
                            </th>
                            <th className="px-8 py-4 text-center">
                              Updated
                            </th>
                            <th className="px-4 py-4 text-center">
                              <div className=" flex justify-center items-center gap-2">
                                <h1>Action</h1>
                                {pharmacyDetail.data.products.length === 0 ? (
                                  ""
                                ) : (
                                  <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleHeaderCheckboxChange}
                                    className="w-4 h-4"
                                  />
                                )}
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm text-left">
                          {status === "loading" ? (
                            <tr>
                              <td colSpan="12" className="text-center py-4">
                                <div className="flex justify-center items-center">
                                  <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                                </div>
                              </td>
                            </tr>
                          ) : pharmacyDetail.data.products.length === 0 ? (
                            <tr>
                              <td
                                colSpan="12"
                                className="text-center text-gray-500 py-4"
                              >
                                No Data
                              </td>
                            </tr>
                          ) : (
                            pharmacyDetail.data.products.map(
                              (product, index) => (
                                <tr key={index} className="bg-white border-b">
                                  <td className="px-4 py-4">
                                    <div className="flex justify-center items-center">
                                      <p className=" text-base text-center font-semibold ">
                                        {index + 1}
                                      </p>
                                    </div>
                                  </td>
                                  <td className="px-2 py-2">
                                    <div className="flex justify-center p-2 items-center">
                                     
                                    <img
                                          src={
                                            product.image && product.image.length > 0
                                              ? product.image[0]
                                              : "default-image-url.jpg"
                                          }
                                          alt="product"
                                          className="w-24 h-24 object-cover rounded-lg"
                                        />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="p-2">
                                      <p className="text-gray-500 text-center">
                                        {product.name}
                                      </p>
                                    </div>
                                  </td>
                                  <td className="px-4 py-4 text-center whitespace-nowrap">
                                    {product.category}
                                  </td>
                                  <td className="px-4 py-4 truncate">
                                    {product.description}
                                  </td>
                                  <td className="px-4 py-4 text-center">
                                    {product.type}
                                  </td>
                                  <td className="px-4 py-4 text-center">
                                    <p>
                                      <span className="mr-1">&#8358;</span>
                                      {formatNumberWithCommas(product.price)}
                                    </p>
                                  </td>

                                  <td className="px-4 py-4 text-center whitespace-nowrap">
                                    {formatCreatedAt(product.created_at)}
                                  </td>
                                  <td className="px-4 py-4 text-center whitespace-nowrap">
                                    {formatCreatedAt(product.updated_at)}
                                  </td>
                                  <td>
                                    <div className="flex gap-[8px] justify-center items-center">
                                      <div>
                                        <PharmacyProductDetail
                                          images={product.image}
                                          category={product.category}
                                          name={product.name}
                                          color={product.color}
                                          size={product.size}
                                          description={product.description}
                                          price={product.price}
                                          type={product.type}
                                          country={
                                            product.country
                                              ? getCountryNameById(
                                                  product.country
                                                )
                                              : "No Info"
                                          }
                                          city={
                                            product.city
                                              ? getCityNameById(product.city)
                                              : "No Info"
                                          }
                                          state={
                                            product.state
                                              ? getStateNameById(product.state)
                                              : "No Info"
                                          }
                                          created_at={formatCreatedAt(
                                            product.created_at
                                          )}
                                          updated_at={formatCreatedAt(
                                            product.updated_at
                                          )}
                                        />
                                      </div>
                                      <div>
                                        <UpdateProductPharmacy
                                          id={id}
                                          pId={product.id}
                                          image={product.image}
                                          category={product.category}
                                          name={product.name}
                                          description={product.description}
                                          price={product.price}
                                          type={product.type}
                                          quantity={product.quantity}
                                          country={product.country}
                                          city={product.city}
                                          state={product.state}
                                        />
                                      </div>
                                      <div>
                                        <DeleteProductPharmacy
                                          id={id}
                                          pId={product.id}
                                          image={product.image}
                                          category={product.category}
                                          name={product.name}
                                          description={product.description}
                                          price={product.price}
                                          type={product.type}
                                        />
                                      </div>
                                      <div>
                                        <input
                                          type="checkbox"
                                          value={product.id}
                                          onChange={(e) =>
                                            handleCheckboxChange(e, product.id)
                                          }
                                          className="w-4 h-4"
                                          checked={selectedProductIds.includes(
                                            product.id
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
          )}
        </div>
      )}
    </div>
  );
};

export default PharmacyDetail;
