import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";

const ImageFullScreen = ({ src, alt }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleImageClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className="relative cursor-pointer" onClick={handleImageClick}>
      <img
        className=" w-32 h-32 ring-2 ring-gray-200 rounded-lg object-cover transition-transform transform hover:scale-105"
        src={src}
        alt={alt}
      
      />
      {isFullScreen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
          <img
            className="max-h-full max-w-full"
            src={src}
            alt={alt}
          />
          <div className="absolute top-0 right-0 m-4">
            <button
              className="bg-red-500 hover:bg-red-600 text-white px-3 py-2 rounded focus:outline-none"
              onClick={handleImageClick}
            >
              <FaTimes className=" text-lg" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageFullScreen;
