import React, { useState, useEffect } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { PiPlusBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { fetchInvestments } from "../../redux/slices/investmentSlice";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import ModalUpdateInvestment from "../../components/modals/investment/ModalUpdateInvestment";
import { ImSpinner2 } from "react-icons/im";
import ModalDeleteInvestment from "../../components/modals/investment/ModalDeleteInvestment";

const InvestmentList = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInvestments());
  }, [dispatch]);

  const investments = useSelector((state) => state.investment?.investments);
  // Filter categories based on search term
  const filteredInvestments = investments.filter((order) =>
    search.toLowerCase() === ""
      ? order
      : order.name.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentInvestments = filteredInvestments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredInvestments.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.investment?.status);
  const error = useSelector((state) => state.investment?.error);



  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Investment
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Investment
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Investments
            </p>
          </div>
        </div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search investment..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>

        <div className="flex items-center gap-4">
          <Link to={"/dashboard/investment/add-investment"}>
            <button
              type="button"
              className="flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium"
            >
              <PiPlusBold className=" text-lg font-extrabold" />
              Add Investment
            </button>
          </Link>
        </div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="w-full text-sm whitespace-nowrap text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-3 text-center">Image</th>
                <th className="px-4 py-3 text-center">Name</th>

                {/* <th className="px-4 py-3 text-center">Amount</th> */}
                <th className="px-4 py-3 text-center">Minimum Investment</th>
                <th className="px-4 py-3 text-center">Minimum No. of Months</th>
                <th className="px-4 py-3 text-center">No. Users</th>

                <th className="px-4 py-3 text-center">Roi (%)</th>
                <th className="px-4 py-3 text-center">Type</th>
                <th className="px-4 py-3 text-center">Start Date</th>

                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) :status === "loading" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentInvestments.length === 0 ? (
                <tr>
                  <td
                    colSpan="12"
                    className="text-center text-base text-gray-500 py-4"
                  >
                    No Data
                  </td>
                </tr>
              ) : (
                currentInvestments?.map((order, index) => (
                  <tr key={index} className="bg-white border-b">
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>
                    <td className="px-4 py-4 ">
                      <div className="flex justify-center items-center">
                        <img
                          src={order.image}
                          alt={order.name}
                          className="w-16 h-16 object-cover rounded-lg"
                        />
                      </div>
                    </td>
                    <td className="px-4 py-3">
                      <p className=" text-[#1F7BF4] text-[14px] text-center font-semibold">
                        {order.name}
                      </p>
                    </td>

                    <td className="px-4 py-2 text-center">
                      <span className="mr-1">&#8358;</span>
                      {formatNumberWithCommas(parseFloat(order.minimum))}
                    </td>
                    <td className="px-4 py-2 text-center">
                      {order.months ? order.months : "0"}
                    </td>

                    <td className="px-4 py-2 text-center">
                      {order.members ? order.members : "-"}
                    </td>

                    <td className="px-4 py-2 text-center">
                      <p>{order.roi}</p>
                    </td>

                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      <p>{order.type}</p>
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(order.created_at)}
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex gap-[8px] justify-center items-center">
                        <ModalUpdateInvestment
                          id={order.id}
                          image={order.image}
                          name={order.name}
                          months={order.months}
                          minimum={order.minimum}
                          roi={order.roi}
                          type={order.type}
                        />
                        <ModalDeleteInvestment
                          id={order.id}
                          name={order.name}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {investments.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentList;
