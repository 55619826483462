import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiService } from "../../services/apiService";

export const fetchOrderPending = createAsyncThunk(
  "orderPending/fetchOrderPending",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get("/admin/orders/pending");

      //   console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const cancelOrder = createAsyncThunk(
  "orderPending/cancelOrder",
  async (orderId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(`/cancel/order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const orderPendingSlice = createSlice({
  name: "orderPending",
  initialState: {
    orderPendings: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderPending.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchOrderPending.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orderPendings = action.payload.data;
      })
      .addCase(fetchOrderPending.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(cancelOrder.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(cancelOrder.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(cancelOrder.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });
  },
});

export default orderPendingSlice.reducer;
