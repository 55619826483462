import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { fetchFuels } from "../../redux/slices/fuelSlice";
import ModalAddFuel from "../../components/modals/fuel/ModalAddFuel";

import { ImSpinner2 } from "react-icons/im";
import ModalUpdateFuel from "../../components/modals/fuel/ModalUpdateFuel";
import ModalDeleteFuel from "../../components/modals/fuel/ModalDeleteFuel";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";

const FuelList = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFuels());
  }, [dispatch]);

  const fuels = useSelector((state) => state.fuel?.fuels);

  const reversedFuels = fuels.slice().reverse();
 
    // Filter categories based on search term
    const filteredFuels = reversedFuels.filter((order) =>
      search.toLowerCase() === ""
        ? order
        : order?.type.toLowerCase().includes(search)
    );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentFuels = filteredFuels.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredFuels.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.fuel?.status);
  const error = useSelector((state) => state.fuel?.error);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  // const countries = useSelector((state) => state.country?.countries);
  // const states = useSelector((state) => state.state?.states);
  // const provinces = useSelector((state) => state.province?.provinces);

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);

    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);
    const state = states.find((state) => state.id === parsedId);
    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Fuel & Gas
          </h1>

          <div className="flex justify-left items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Fuel & Gas
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Fuel & Gas List
            </p>
          </div>
        </div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search ..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>

        <div className="flex items-center gap-4">
          <ModalAddFuel />
        </div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="w-full text-sm whitespace-nowrap text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-3 text-center">Name</th>
                <th className="px-4 py-3 text-center">Price</th>
                <th className="px-4 py-3 text-center">Country</th>
                <th className="px-4 py-3 text-center">State</th>
                <th className="px-4 py-3 text-center">City</th>
                <th className="px-4 py-3 text-center">Created</th>
                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
              {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : (
                currentFuels?.map((order, index) => (
                    <tr key={index} className="bg-white border-b">
                      <td className="px-4 py-4 ">
                        <div className="flex justify-center items-center">
                          <p className=" text-base text-center font-semibold ">
                            {index + 1}
                          </p>
                        </div>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <p className="text-sm font-medium text-center whitespace-nowrap text-gray-800">
                          {order.type}
                        </p>
                      </td>
                      <td className="px-4 py-2">
                        <p className="text-sm font-medium text-center whitespace-nowrap text-gray-800">
                          <span className="mr-1">&#8358;</span>{" "}
                          {formatNumberWithCommas(order.price)}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <p className="text-sm font-medium text-center whitespace-nowrap text-gray-800">
                          {getCountryNameById(order.country)}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <p className="text-sm font-medium text-center whitespace-nowrap text-gray-800">
                          {getStateNameById(order.state)}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <p className="text-sm font-medium text-center whitespace-nowrap text-gray-800">
                          {getCityNameById(order.city)}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center whitespace-nowrap">
                        {formatCreatedAt(order.created_at)}
                      </td>

                      <td className="px-4 py-4">
                        <div className="flex gap-4 justify-center items-center">
                          <div className="mr-1">
                            <ModalUpdateFuel
                              id={order.id}
                              type={order.type}
                              price={order.price}
                              city={order.city}
                              state={order.state}
                              country={order.country}
                            />
                          </div>
                          <div>
                            <ModalDeleteFuel
                              id={order.id}
                              fuelType={order.type}
                              fuelPrice={order.price}
                              fuelCity={getCityNameById(order.city)}
                              fuelState={getStateNameById(order.state)}
                              fuelCountry={getCountryNameById(order.country)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {fuels.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuelList;
