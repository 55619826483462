import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";
import { PiPlusBold } from "react-icons/pi";
import { Link } from "react-router-dom";

import { formatCreatedAt } from "../../utils/commonUtils";

import { useDispatch, useSelector } from "react-redux";

import { fetchStore } from "../../redux/slices/storeSlice";

import ModalDeleteStore from "../../components/modals/modalStore/ModalDeleteStore";
import { ImSpinner2 } from "react-icons/im";
import ModalUpdateStore from "../../components/modals/modalStore/ModalUpdateStore";

const Stores = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStore());
  }, [dispatch]);

  const stores = useSelector((state) => state.store?.stores);

  // Filter categories based on search term
  const filteredStores = stores.filter((market) =>
    search.toLowerCase() === ""
      ? market
      : market.firstname.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentStores = filteredStores.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredStores.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.store?.status);
  const error = useSelector((state) => state.store?.error);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);

    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);

    const state = states.find((state) => state.id === parsedId);

    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Stores
          </h1>

          <div className="flex mb-4 justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Store</p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">Stores</p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search ..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>

        <div className="flex items-center gap-4">
          <Link to="/dashboard/shopping/add-store">
            <button
              type="button"
              className="flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium"
            >
              <PiPlusBold className=" text-lg font-extrabold" />
              Add Store
            </button>
          </Link>
        </div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="w-full text-sm text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-4 text-center">No.</th>
                <th className="px-12 py-4 text-center">Image</th>
                <th className="px-4 py-4 text-center">Name</th>
                <th className="px-4 py-4 text-center">Country</th>
                <th className="px-4 py-4 text-center">State</th>
                <th className="px-4 py-4 text-center">City</th>
                <th className="px-4 py-4 text-center">Status</th>
                <th className="px-4 py-4 text-center">Landmark</th>
                <th className="px-4 py-4 text-center">Location</th>
                <th className="px-4 py-4 text-center">Added</th>
                <th className="px-4 py-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-full text-sm text-gray-700">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentStores.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentStores?.map((store, index) => (
                  <tr key={index} className="bg-white border-b">
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        <p className=" text-base text-center font-semibold ">
                          {indexOfFirstItem + index + 1}
                        </p>
                      </div>
                    </td>

                    <td className="px-2 py-2">
                      <div className="flex items-center p-2 justify-center">
                        <img
                          src={store.image}
                          alt="store"
                          className="w-24 h-24 object-cover rounded-lg"
                        />
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <Link to={`/dashboard/shopping/stores/${store.id}`}>
                        <div className="ml-2">
                          <p className="text-blue-600 text-center font-semibold whitespace-nowrap">
                            {store.name}
                          </p>
                        </div>
                      </Link>
                    </td>
                    <td className="px-4 py-4 text-center">
                      {getCountryNameById(store.country)}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {getStateNameById(store.state)}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {getCityNameById(store.province)}
                    </td>
                    <td className="px-4 py-4 text-center">{store.type}</td>
                    <td className="px-4 py-4 text-center whitespace-nowrap">
                      {store.landmark}
                    </td>
                    <td className="px-4 py-4 text-center whitespace-nowrap">
                      {store.location}
                    </td>
                    <td className="px-4 py-4 text-center whitespace-nowrap">
                      {formatCreatedAt(store.created_at)}
                    </td>

                    <td className="px-2 py-2">
                      <div className="flex gap-4 justify-center">
                        <Link to={`/dashboard/shopping/stores/${store.id}`}>
                          <IoEyeOutline className="w-4 h-4 text-[#667085]" />
                        </Link>
                        <ModalUpdateStore
                          id={store.id}
                          image={store.image}
                          name={store.name}
                          note={store.note}
                          landmark={store.landmark}
                          location={store.location}
                          type={store.type}
                          country={store.country}
                          state={store.state}
                          province={store.province}
                        />
                        <ModalDeleteStore
                          id={store.id}
                          image={store.image}
                          name={store.name}
                          landmark={store.landmark}
                          location={store.location}
                          type={store.type}
                          country={getStateNameById(store.country)}
                          state={getStateNameById(store.state)}
                          cities={getCityNameById(store.province)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {stores.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stores;
