// ordersSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchOrders = createAsyncThunk(
  "order/fetchOrders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get("/admin/orders");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSingleOrder = createAsyncThunk(
  "singleOrderDetail/fetchSingleOrder",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.get(`/view/order/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchFutureDeliveries = createAsyncThunk(
  "order/fetchFutureDeliveries",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/admin/future-deliveries", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    futureDeliveries: [],
    status: "idle",
    error: null,
    singleOrder: null,
    orderDetailStatus: "idle",
  },
  reducers: {
    updateOrderDetails: (state, action) => {
      if (state.singleOrder) {
        state.singleOrder.order_details = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload.data;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSingleOrder.pending, (state) => {
        state.orderDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchSingleOrder.fulfilled, (state, action) => {
        state.orderDetailStatus = "success";
        state.singleOrder = action.payload.data;
      })
      .addCase(fetchSingleOrder.rejected, (state, action) => {
        state.orderDetailStatus = "failed";
        state.error = action.payload.message;
      })
      .addCase(fetchFutureDeliveries.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchFutureDeliveries.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.futureDeliveries = action.payload.data;
      })
      .addCase(fetchFutureDeliveries.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default orderSlice.reducer;
