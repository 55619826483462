// authThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginAPI } from "../../services/apiService";

export const loginAdminAsync = createAsyncThunk(
  "auth/loginAdminAsync",
  async (formData, thunkAPI) => {
    try {
      const response = await loginAPI(formData);
      return response;
      
    } catch (error) {

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// export const verifyOTPAsync = createAsyncThunk(
//   "auth/verifyOTPAsync",
//   async ({ token }, thunkAPI) => {
//     try {
//       const state = thunkAPI.getState();
//       const email = state.auth.email;

//       const response = await verifyOTPAPI({ token, email });

//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(
//         error.message || "OTP verification failed"
//       );
//     }
//   }
// );
