import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import { formatNumberWithCommas2 } from "../../utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import { addWalletRoiFee } from "../../redux/slices/feeSettingSlice";
import { FaSpinner } from "react-icons/fa";

const DailywalletRoiSetting = () => {
  const [formData, setFormData] = useState({
    amount: "",
    min_fee: "",
    max_fee: "",
  });

  const dispatch = useDispatch();

  const wallet_roi = useSelector(
    (state) => state.feeSetting?.feeSettings?.wallet_roi
  );

  useEffect(() => {
    if (wallet_roi) {
      setFormData({
        amount: wallet_roi.amount || "",
        min_fee: wallet_roi.min_fee || "",
        max_fee: wallet_roi.max_fee || "",
      });
    }
  }, [wallet_roi]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let adjustedValue = value.replace(/,/g, "");

    if (name === "amount") {
      const floatValue = parseFloat(value);
      adjustedValue = Math.min(Math.max(floatValue, 1), 100).toString();
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: adjustedValue,
    }));
  };

  const status = useSelector((state) => state.feeSetting?.walletRoiFeeStatus);

  const handleSubmit = async () => {
    if (!formData.amount || !formData.min_fee || !formData.max_fee) {
      toast.error("Please fill in all the required fields.");
      return;
    }
    try {
      await dispatch(addWalletRoiFee(formData));
      toast.success("Daily Wallet Roi fee successfully set");
    } catch (error) {}
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center gap-4">
        <label
          htmlFor="amount"
          className="text-[14px] text-[#4D5464]  font-bold tracking-[0.07px] pl-28"
        >
          Amount (%)
        </label>
        <div className="flex justify-end pr-28">
          <input
            name="amount"
            id="name"
            type="number"
            value={formData.amount}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-[#333843] text-right p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex flex-row mt-4 justify-between items-center">
        <label
          htmlFor="min_fee"
          className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px] pl-28"
        >
          Minimun Fee
        </label>
        <div className="flex justify-end pr-28">
          <input
            name="min_fee"
            id="name"
            type="text"
            value={formatNumberWithCommas2(formData.min_fee)}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-[#333843] text-right p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex flex-row mt-4 justify-between items-center">
        <label
          htmlFor="max_fee"
          className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px] pl-28"
        >
          Maximun Fee
        </label>
        <div className="flex justify-end pr-28">
          <input
            name="max_fee"
            id="name"
            type="text"
            value={formatNumberWithCommas2(formData.max_fee)}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-[#333843] text-right p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex justify-center">
        <button
          className="flex gap-1 mt-4  rounded-lg items-center justify-center bg-blue-600 text-white py-2 px-4"
          disabled={status === "loading"}
          onClick={handleSubmit}
        >
          {status === "loading" ? (
            <>
              <FaSpinner className="animate-spin" />
              <p className="text-sm font-semibold whitespace-nowrap">
                Submitting ...
              </p>
            </>
          ) : (
            <>
              <FiPlus className=" font-bold text-base" />
              <p className="text-sm font-semibold whitespace-nowrap">Submit</p>
            </>
          )}
        </button>
      </div>
    </>
  );
};

export default DailywalletRoiSetting;
