import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { ImSpinner2 } from "react-icons/im";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import ApproveShopperPayment from "../../components/modals/payment/ApproveShopperPayment";
import { fetchPayment } from "../../redux/slices/paymentSlice";

const ShopperPayment = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPayment());
  }, [dispatch]);

  const payments = useSelector((state) => state.payment?.payments);

  const status = useSelector((state) => state.payment?.status);
  const error = useSelector((state) => state.payment?.error);

  const shopperPayments = payments?.filter(
    (payment) => payment?.type === "shopper" || payment?.type === "Shopper"
  );

  // Filter categories based on search term
  const filteredShopperPayments = shopperPayments.filter((user) =>
    search.toLowerCase() === ""
      ? user
      : user.name.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentShopperPayments = filteredShopperPayments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredShopperPayments.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Shopper Cash Request
          </h1>

          <div className="flex justify-left items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">Shopper</p>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Shopper Cash Request
            </p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-4 text-center">Name</th>
                <th className="px-4 py-4 text-center">Order Id</th>
                <th className="px-4 py-4 text-left">Account Details</th>
                <th className="px-2 py-3 text-center">Amount</th>
                <th className="px-2 py-3 text-center">Market Name</th>
                <th className="px-2 py-3 text-center">Created</th>
                <th className="px-2 py-3 text-center">Updated</th>
                <th className="px-2 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm text-left">
              {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later{" "}
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentShopperPayments?.length === 0 ? (
                <tr>
                  <td
                    colSpan="12"
                    className="text-center text-base text-gray-500 py-4"
                  >
                    No Data
                  </td>
                </tr>
              ) : (
                currentShopperPayments?.map((user, index) => (
                  <tr key={user.id}>
                    <td className="px-4 py-4 ">
                      <div className="flex justify-center items-center">
                        <p className=" text-base text-center font-semibold ">
                          {index + 1}
                        </p>
                      </div>
                    </td>

                    <td className="px-4 py-4">
                      <div className="flex flex-col text-center gap-2">
                        <p className=" text-blue-600 text-base font-semibold">
                          {user.user_name}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-center ">
                      {user.order_id ? (
                        <Link
                          to={`/dashboard/orders/orders-detail/${user.order_id}`}
                        >
                          <p className="hover:text-blue-600 text-gray-600  text-base font-semibold hover:font-bold hover:text-lg">
                            {user.order_id}
                          </p>
                        </Link>
                      ) : (
                        "No order"
                      )}
                    </td>
                    <td className="px-4 py-4 text-left">
                      <div className=" flex flex-col gap-2">
                        <p>{user.payment_details.name}</p>
                        <p>{user.payment_details.bank}</p>
                        <p>{user.payment_details.account}</p>
                        <p>{user.payment_details.phone}</p>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <span className="mr-1">&#8358;</span>
                      {formatNumberWithCommas(user.amount)}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {user.market_name ? user.market_name : "No Data"}
                    </td>
                    <td className="px-2 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(user.created_at)}
                    </td>
                    <td className="px-2 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(user.updated_at)}
                    </td>
                    <td className="px-2 py-2">
                      <div className="flex gap-4 justify-center items-center">
                        <div className=" ">
                          <ApproveShopperPayment
                            id={user.id}
                            name={user.user_name}
                            amount={user.amount}
                            shopper_id={user.shopper_id}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {shopperPayments.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopperPayment;
