import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchRole } from "../../redux/slices/rolesSlice";
import { ImSpinner2 } from "react-icons/im";
import { formatCreatedAt } from "../../utils/commonUtils";
import ModalAddAdminRole from "../../components/modals/admin/ModalAddAdminRole";
import ModalUpdateAdminRole from "../../components/modals/admin/ModalUpdateAdminRole";

const AdminRole = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRole());
  }, [dispatch]);

  const roles = useSelector((state) => state.role?.roles);
  const status = useSelector((state) => state.role?.status);
  const error = useSelector((state) => state.role?.error);

  const reverseRoles = roles.slice().reverse();

  // Filter categories based on search term
  const filteredRoles = reverseRoles.filter((role) =>
    search.toLowerCase() === ""
      ? role
      : role.name.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentRoles = filteredRoles.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredRoles.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between mb-4">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Admin Roles
          </h1>
          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>
            <HiChevronRight className="text-gray-500" />
            <p className="text-[14px] text-blue-500 font-medium">Admin</p>
            <HiChevronRight className="text-gray-500" />
            <p className="text-[14px] text-gray-500 font-medium">Admin Roles</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4 mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search ..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
        <ModalAddAdminRole />
      </div>

      <div className="bg-white mt-6 rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
              <tr>
                <th className="px-4 py-4 text-center">No.</th>
                <th className="px-4 py-4 text-left">Roles</th>
                {/* <th className="px-4 py-4 text-left">Functions</th> */}
                <th className="px-4 py-4 text-center">Created</th>
                <th className="px-4 py-4 text-center">Updated</th>
                <th className="px-4 py-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm text-left">
            {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) :status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : (
                currentRoles?.map((role, index) => (
                  <tr key={role.id} className=" whitespace-nowrap">
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        <p className=" text-base text-center font-semibold ">
                          {index + 1}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex flex-col text-left ">
                        <p className="text-base font-medium ">{role.name}</p>
                      </div>
                    </td>
                    {/* <td className="px-4 py-4">
                      <div className="flex flex-col">
                        {Array.isArray(role.role) && role.role.length > 0 ? (
                          role.role.map((r, index) => (
                            <p key={index} className="text-left">
                              {r}
                            </p>
                          ))
                        ) : (
                          <p className="text-left text-gray-500">
                            No functions available
                          </p>
                        )}
                      </div>
                    </td> */}

                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(role.created_at)}
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(role.updated_at)}
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex gap-4 justify-center">
                        <div>
                          <ModalUpdateAdminRole id={role.id} name={role.name} />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {roles.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminRole;
