import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiService } from "../../services/apiService";

export const fetchIsusu = createAsyncThunk(
  "isusu/fetchIsusu",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/all-contributions", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchIsusuApprovalRequest = createAsyncThunk(
  "isusu/fetchIsusuApprovalRequest",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/isusu-requests", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchIsusuDefaulter = createAsyncThunk(
  "isusu/fetchIsusuDefaulter",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/isusu/defaulters", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchIsusuDropoff = createAsyncThunk(
  "isusu/fetchIsusuDropoff",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/isusu/payouts", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchContributionDetail = createAsyncThunk(
  "contribution/fetchContributionDetail",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get(`/get-contribution/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const approveIsusuRequest = createAsyncThunk(
  "isusu/approveIsusuRequest ",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/approve-user-isusu", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const approveIsusuAdminPayment = createAsyncThunk(
  "isusu/approveIsusuAdminPayment",
  async ({user_id, amount, group_id, username}, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/contribute", {user_id, amount, group_id, username}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const isusuSlice = createSlice({
  name: "isusu",
  initialState: {
    isusu: [],
    isusuApprovalRequests: [],
    isusuDefaulters: [],
    isusuDropoffs: [],
    status: "idle",
    error: null,
    contributionDetail: null,
    contributionDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIsusu.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchIsusu.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isusu = action.payload.data;
      })
      .addCase(fetchIsusu.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchContributionDetail.pending, (state) => {
        state.contributionDetailStatus = "loading"; // Update the contribution detail loading status
        state.error = null;
      })
      .addCase(fetchContributionDetail.fulfilled, (state, action) => {
        state.contributionDetailStatus = "succeeded"; // Update the contribution detail status on successful fetch
        state.contributionDetail = action.payload; // Update contributionDetail on successful fetch
      })
      .addCase(fetchContributionDetail.rejected, (state, action) => {
        state.contributionDetailStatus = "failed"; // Update the contribution detail status on failure
        state.error = action.error.message;
      })
      .addCase(fetchIsusuApprovalRequest.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchIsusuApprovalRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isusuApprovalRequests = action.payload.data;
      })
      .addCase(fetchIsusuApprovalRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(approveIsusuRequest.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(approveIsusuRequest.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(approveIsusuRequest.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      })
      .addCase(fetchIsusuDefaulter.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchIsusuDefaulter.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isusuDefaulters = action.payload.data;
      })
      .addCase(fetchIsusuDefaulter.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchIsusuDropoff.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchIsusuDropoff.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isusuDropoffs = action.payload.data;
      })
      .addCase(fetchIsusuDropoff.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(approveIsusuAdminPayment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(approveIsusuAdminPayment.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(approveIsusuAdminPayment.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      })
  },
});

export const selectContributionDetail = (state) =>
  state.isusu?.contributionDetail;

export default isusuSlice.reducer;
