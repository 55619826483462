import React, { useEffect, useState } from "react";
import { FaEdit, FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { fetchFuels, updateFuel } from "../../../redux/slices/fuelSlice";

export default function ModalUpdateFuel({
  id,
  type,
  price,
  city,
  state,
  country,
}) {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const status = useSelector((state) => state.fuel?.status);

  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    setUpdateData({ type, price, city, state, country });
  }, [type, price, city, state, country]);

  const countries = useSelector((state) => state.country?.countries);

  const states = useSelector((state) => state.state?.states);

  const provinces = useSelector((state) => state.province?.provinces);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUpdateData({
      ...updateData,
      [name]: value,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(updateFuel({ id, updateData }));

      await dispatch(fetchFuels());
      toast.success(res.payload.message);
    } catch (error) {
      console.error(error);
    }

    setShowModal(false);
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <FaEdit className="text-gray-500 text-base " />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Update Fuel & Gas
                  </h1>
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-row gap-6 ">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="type"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Type
                        </label>
                        <div className="flex items-center">
                          <input
                            name="type"
                            id="type"
                            type="text"
                            value={updateData && updateData.type}
                            onChange={handleInputChange}
                            placeholder="Type here..."
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="price"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Price
                        </label>
                        <div className="flex items-center">
                          <input
                            name="price"
                            id="price"
                            type="number"
                            value={updateData && updateData.price}
                            onChange={handleInputChange}
                            placeholder="Type price here..."
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="country"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Name of Country
                        </label>
                        <div className="flex items-center">
                          <select
                            name="country"
                            id="country"
                            value={updateData.country}
                            onChange={handleInputChange}
                            className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          >
                            {countries?.map((country) => (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="state"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Name of State
                        </label>
                        <div className="flex items-center">
                          <select
                            name="state"
                            id="state"
                            value={updateData.state}
                            onChange={handleInputChange}
                            className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          >
                            {states
                              ?.filter(
                                (state) =>
                                  state.country_id === updateData.country
                              )
                              .map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="city"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Name of City
                        </label>
                        <div className="flex items-center">
                          <select
                            name="city"
                            id="city"
                            value={updateData.city}
                            onChange={handleInputChange}
                            className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          >
                            {provinces
                              ?.filter(
                                (province) =>
                                  province.country_id === updateData.country &&
                                  province.state_id === updateData.state
                              )
                              .map((province) => (
                                <option key={province.id} value={province.id}>
                                  {province.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="flex mt-6 gap-4 justify-center">
                        <button
                          onClick={() => setShowModal(false)}
                          className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                        >
                          Cancel
                        </button>
                        <button
                          className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                          disabled={status === "loading"}
                          onClick={handleUpdate}
                        >
                          {status === "loading" ? (
                            <>
                              <FaSpinner className="animate-spin" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                                Updating ...
                              </p>
                            </>
                          ) : (
                            <>
                              <FaTimes className="w-4 h-4" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                                Update Fuel & Gas
                              </p>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
