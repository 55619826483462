import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchProducts = createAsyncThunk(
  "product/fetchProducts",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.get("/admin/products", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addProduct = createAsyncThunk(
  "product/addProduct",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/create/product", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (pId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const id = pId;
      const response = await apiService.delete(`/product/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const duplicateProduct = createAsyncThunk(
  "product/duplicateProduct",
  async (
    { fromOwnerType, fromOwnerId, toOwnerType, toOwnerId },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post(
        `duplicate-products/${fromOwnerType}/${fromOwnerId}/${toOwnerType}/${toOwnerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async ({ pId, formData }, { rejectWithValue }) => {
    try {
      const id = pId;
      const token = localStorage.getItem("token");
      const response = await apiService.put(`/product/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "applications/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProductDetail = createAsyncThunk(
  "product/fetchProductDetail ",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.get(`/product/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addDiscountPrice = createAsyncThunk(
  "product/addDiscountPrice",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post(
        "/product/discount-price",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addIncrementPrice = createAsyncThunk(
  "product/addIncrementPrice",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post(
        "/product/increment-price",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    status: "idle",
    error: null,
    productDetail: null,
    productDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload.data;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLoading = false;
        state.products.unshift(action.payload);
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        const product = action.payload;
        state.products = state.products?.map((prod) =>
          prod.id === product.id ? product : prod
        );
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.status = "success";
        state.products = state.products.filter(
          (product) => product.id !== action.payload.id
        );
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to delete product";
      })
      .addCase(fetchProductDetail.pending, (state) => {
        state.productDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchProductDetail.fulfilled, (state, action) => {
        state.productDetailStatus = "succeeded";
        state.productDetail = action.payload;
      })
      .addCase(fetchProductDetail.rejected, (state, action) => {
        state.productDetailStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(duplicateProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(duplicateProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.payload = action.payload;
      })
      .addCase(duplicateProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addDiscountPrice.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addDiscountPrice.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(addDiscountPrice.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      })
      .addCase(addIncrementPrice.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addIncrementPrice.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(addIncrementPrice.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      });
  },
});

export const selectProductDetail = (state) => state.product?.productDetail;

export default productSlice.reducer;
