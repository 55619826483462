import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { fetchOrderOngoings } from "../../redux/slices/orderOngoingSlice";

import { ImSpinner2 } from "react-icons/im";
import { formatCreatedAt, formatNumberWithCommas } from "../../utils/commonUtils";

const OrderOngoing = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrderOngoings());
  }, [dispatch]);

  const orderOngoings = useSelector(
    (state) => state.orderOngoing?.orderOngoings
  );

  // Filter categories based on search term
  const filteredOrderOngoings = orderOngoings.filter(
    (order) =>
      search.toLowerCase() === "" ||
      order?.customer?.name.toLowerCase().includes(search) ||
      order.order_id.toString().toLowerCase().includes(search.toLowerCase())
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentOrderOngoings = filteredOrderOngoings.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredOrderOngoings.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.orderOngoing?.status);
  const error = useSelector((state) => state.orderOngoing?.error);



  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Ongoing Order
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Shopping</p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Order Ongoing
            </p>
          </div>
        </div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search orders..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>

       
      </div>
      <div className="bg-white rounded-lg w-full h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left whitespace-nowrap text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50">
              <tr>
                <th className="px-3 py-3 text-center">No.</th>
                <th className="px-4 text-left py-3">Customer</th>
                <th className="px-3 py-3 text-center">Order ID</th>
                <th className="px-4 py-3 text-center">Capfields</th>

                <th className="px-4 py-3 text-center">Total</th>
                <th className="px-4 py-3 text-center">Created</th>
                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
            {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentOrderOngoings.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentOrderOngoings?.map((order, index) => (
                  <tr
                    key={order.id}
                    className="bg-white border-b whitespace-nowrap"
                  >
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        <p className=" text-base text-center font-semibold ">
                          {indexOfFirstItem + index + 1}
                        </p>
                      </div>
                    </td>
                    
                    <td className="px-4 py-2 text-left">
                      <div className=" flex flex-col gap-2">
                        <p className="  font-semibold">
                          {order.customer?.name}
                        </p>
                        <p>{order.customer?.email}</p>
                        <p className="font-bold">{order.customer?.phone}</p>
                      </div>
                    </td>
                    <td className="px-4 py-4 font-medium whitespace-nowrap text-center">
                      <Link
                        to={`/dashboard/orders/ongoing-order-detail/${order.order_id}`}
                      >
                        <p className="px-4 py-1 text-base font-semibold text-blue-600">
                          {order.order_id}
                        </p>
                      </Link>
                    </td>


                    <td className="px-4 py-2 text-center">
                      {" "}
                      <span className="mr-1">&#8358;</span>
                      {formatNumberWithCommas(order.capfields)}
                    </td>

                    <td className="px-4 py-2 text-center">
                      <span className="mr-1">&#8358;</span>
                      {formatNumberWithCommas(order.total_amount)}
                    </td>

                    <td className="px-4 py-2 text-center">
                      {formatCreatedAt(order.created_at)}
                    </td>
                    <td className="px-2 py-4">
                      <div className="flex gap-4 justify-center">
                        <Link
                          to={`/dashboard/orders/ongoing-order-detail/${order.order_id}`}
                        >
                          <IoEyeOutline className="w-4 h-4 text-[#667085]" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {orderOngoings.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderOngoing;
