import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCompletedPayment } from "../../redux/slices/paymentSlice";
import { ImSpinner2 } from "react-icons/im";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";

const ShopperCompletedPayment = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompletedPayment());
  }, [dispatch]);

  const completedpayments = useSelector(
    (state) => state.payment?.completedPayments
  );

  // console.log(completedpayments);
  const status = useSelector((state) => state.payment?.status);

  const shopperPayments = completedpayments?.payments?.filter(
    (cp) => cp?.type === "Shopper"
  );

  const shopperWithdrawals = completedpayments?.withdrawals?.filter(
    (cp) => cp?.type === "shopper"
  );

  const shopperRemits = completedpayments?.remits?.filter(
    (cp) => cp?.type === "shopper"
  );

  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [showWithdrawalsModal, setShowWithdrawalsModal] = useState(false);
  const [showRemittanceModal, setShowRemittanceModal] = useState(false);

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Shopper Completed Payments
          </h1>

          <div className="flex justify-left items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">Shopper</p>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Shopper Completed Payments
            </p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4"></div>
      <div className=" flex flex-row gap-4">
        <div className=" w-6/12">
          <div className=" flex flex-col gap-2">
            {/* Payment */}
            <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
              <div className=" flex justify-between p-6">
                <p className=" font-bold text-base uppercase text-gray-500">
                  Shopper Cash Payments
                </p>
                <div className="bg-gray-500  hover:bg-blue-500 ease-in duration-300 text-gray-50 p-2  rounded-lg">
                  <button
                    className=" text-sm hover:text-base font-medium ease-in duration-300"
                    onClick={() => setShowPaymentsModal(true)}
                  >
                    See More
                  </button>
                </div>
              </div>
              <div className="relative overflow-x-auto rounded-t-lg">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-center">No.</th>
                      <th className="px-4 py-4 text-center">Name</th>
                      <th className="px-4 py-4 text-center">Order Id</th>
                      <th className="px-2 py-3 text-center">Amount</th>
                      <th className="px-2 py-3 text-center">Created</th>
                      <th className="px-2 py-3 text-center">Updated</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm text-left">
                    {status === "loading" ? (
                      <tr>
                        <td colSpan="8" className="text-center py-4">
                          <div className="flex justify-center items-center">
                            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                          </div>
                        </td>
                      </tr>
                    ) : shopperPayments?.length === 0 ? (
                      <tr>
                        <td
                          colSpan="12"
                          className="text-center text-base text-gray-500 py-4"
                        >
                          No Data
                        </td>
                      </tr>
                    ) : (
                      shopperPayments?.slice(0, 3).map((user, index) => (
                        <tr key={user.id}>
                          <td className="px-4 py-4 ">
                            <div className="flex justify-center items-center">
                              <p className=" text-base text-center font-semibold ">
                                {index + 1}
                              </p>
                            </div>
                          </td>

                          <td className="px-4 py-4">
                            <div className="flex flex-col text-center gap-2">
                              <p className=" text-blue-600 text-base font-semibold whitespace-nowrap">
                                {user.user_name}
                              </p>
                            </div>
                          </td>
                          <td className="px-4 py-4 text-center whitespace-nowrap">
                            {user.order_id ? (
                              <Link
                                to={`/dashboard/orders/orders-detail/${user.order_id}`}
                              >
                                <p className="hover:text-blue-600 text-gray-600  text-base font-medium hover:font-semibold">
                                  {user.order_id}
                                </p>
                              </Link>
                            ) : (
                              "No order"
                            )}
                          </td>

                          <td className="px-4 py-4 text-center">
                            <span className="mr-1">&#8358;</span>
                            {formatNumberWithCommas(user.amount)}
                          </td>

                          <td className="px-2 py-2 text-center whitespace-nowrap">
                            {formatCreatedAt(user.created_at)}
                          </td>
                          <td className="px-2 py-2 text-center whitespace-nowrap">
                            {formatCreatedAt(user.updated_at)}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* Witdrawal */}
            <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
              <div className=" flex justify-between p-6">
                <p className=" font-bold text-base uppercase text-gray-500">
                  Withdrawls
                </p>
                <div className="bg-gray-500  hover:bg-blue-500 ease-in duration-300 text-gray-50 p-2  rounded-lg">
                  <button
                    className=" text-sm hover:text-base font-medium ease-in duration-300"
                    onClick={() => setShowWithdrawalsModal(true)}
                  >
                    See More
                  </button>
                </div>
              </div>
              <div className="relative overflow-x-auto rounded-t-lg">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-center">No.</th>
                      <th className="px-4 py-4 text-center">Name</th>
                      <th className="px-2 py-3 text-center">Amount</th>
                      <th className="px-2 py-3 text-center">Created</th>
                      <th className="px-2 py-3 text-center">Updated</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm text-left">
                    {status === "loading" ? (
                      <tr>
                        <td colSpan="8" className="text-center py-4">
                          <div className="flex justify-center items-center">
                            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                          </div>
                        </td>
                      </tr>
                    ) : shopperWithdrawals?.length === 0 ? (
                      <tr>
                        <td
                          colSpan="12"
                          className="text-center text-base text-gray-500 py-4"
                        >
                          No Data
                        </td>
                      </tr>
                    ) : (
                      shopperWithdrawals?.slice(0, 3).map((user, index) => (
                        <tr key={user.id} className=" whitespace-nowrap">
                          <td className="px-4 py-4 ">
                            <div className="flex justify-center items-center">
                              <p className=" text-base text-center font-semibold ">
                                {index + 1}
                              </p>
                            </div>
                          </td>

                          <td className="px-4 py-4">
                            <div className="flex flex-col text-center gap-2">
                              <p className=" text-blue-600 text-base font-semibold">
                                {user.name}
                              </p>
                            </div>
                          </td>

                          <td className="px-4 py-4 text-center">
                            <span className="mr-1">&#8358;</span>
                            {formatNumberWithCommas(user.amount)}
                          </td>

                          <td className="px-2 py-2 text-center whitespace-nowrap">
                            {formatCreatedAt(user.created_at)}
                          </td>
                          <td className="px-2 py-2 text-center whitespace-nowrap">
                            {formatCreatedAt(user.updated_at)}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="w-6/12">
          {/* Remittance */}
          <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
            <div className=" flex justify-between  p-6">
              <p className=" font-bold text-base uppercase text-gray-500">
                Remittance
              </p>
              <div className="bg-gray-500  hover:bg-blue-500 ease-in duration-300 text-gray-50 p-2  rounded-lg">
                <button
                  className=" text-sm hover:text-base font-medium ease-in duration-300"
                  onClick={() => setShowRemittanceModal(true)}
                >
                  See More
                </button>
              </div>
            </div>
            <div className="relative overflow-x-auto rounded-t-lg">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                  <tr>
                    <th className="px-4 py-3 text-center">No.</th>
                    <th className="px-4 py-4 text-center">Name</th>
                    <th className="px-2 py-3 text-center">Amount</th>
                    <th className="px-2 py-3 text-center">Created</th>
                    <th className="px-2 py-3 text-center">Updated</th>
                  </tr>
                </thead>
                <tbody className="text-sm text-left">
                  {status === "loading" ? (
                    <tr>
                      <td colSpan="8" className="text-center py-4">
                        <div className="flex justify-center items-center">
                          <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                        </div>
                      </td>
                    </tr>
                  ) : shopperRemits?.length === 0 ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center text-base text-gray-500 py-4"
                      >
                        No Data
                      </td>
                    </tr>
                  ) : (
                    shopperRemits?.slice(0, 3).map((user, index) => (
                      <tr key={user.id}>
                        <td className="px-4 py-4 ">
                          <div className="flex justify-center items-center">
                            <p className=" text-base text-center font-semibold ">
                              {index + 1}
                            </p>
                          </div>
                        </td>

                        <td className="px-4 py-4">
                          <div className="flex flex-col text-center gap-2">
                            <p className=" text-blue-600 text-base font-semibold">
                              {user.name}
                            </p>
                          </div>
                        </td>

                        <td className="px-4 py-4 text-center">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(user.amount)}
                        </td>

                        <td className="px-2 py-2 text-center whitespace-nowrap">
                          {formatCreatedAt(user.created_at)}
                        </td>
                        <td className="px-2 py-2 text-center whitespace-nowrap">
                          {formatCreatedAt(user.updated_at)}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      {showPaymentsModal && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-12 rounded-lg max-w-6xl w-full max-h-[100vh] overflow-y-auto">
            <div className=" flex justify-between p-2">
              <h2 className="text-2xl font-semibold text-gray-500 mt-4 mb-4">
                Shopper Cash Payments
              </h2>
              <button
                onClick={() => setShowPaymentsModal(false)}
                className="px-2 py-2 m-4 text-white bg-blue-500 ease-out duration-500 rounded-md font-bold text-sm hover:text-base "
              >
                Close
              </button>
            </div>
            <table className="w-full text-sm whitespace-nowrap text-center text-gray-500">
              <thead className="text-sm text-gray-700 bg-gray-100">
                <tr>
                  <th className="px-4 py-4">No.</th>
                  <th className="px-4 py-4">Name</th>
                  <th className="px-4 py-4">Order Id</th>
                  <th className="px-4 py-4">Amount</th>
                  <th className="px-4 py-4">Created</th>
                  <th className="px-4 py-4">Updated</th>
                </tr>
              </thead>
              <tbody>
                {shopperPayments.map((payment, index) => (
                  <tr key={payment.id}>
                    <td className="px-4 py-4">{index + 1}</td>
                    <td className="px-4 py-4">{payment.user_name}</td>
                    <td className="px-4 py-4">
                      {payment.order_id ? (
                        <Link
                          to={`/dashboard/orders/orders-detail/${payment.order_id}`}
                          className="hover:text-blue-600 text-gray-600  text-base font-medium hover:font-semibold"
                        >
                          {payment.order_id}
                        </Link>
                      ) : (
                        "No order"
                      )}
                    </td>
                    <td className="px-4 py-4">
                      <span className="ml-1">&#8358;</span>{" "}
                      {formatNumberWithCommas(payment.amount)}
                    </td>
                    <td className="px-4 py-4">
                      {formatCreatedAt(payment.created_at)}
                    </td>
                    <td className="px-4 py-4">
                      {formatCreatedAt(payment.updated_at)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {showWithdrawalsModal && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-12 rounded-lg max-w-6xl w-full max-h-[100vh] overflow-y-auto">
            <div className=" flex justify-between  p-2">
              <h2 className="text-2xl font-semibold text-gray-500 mt-4 mb-4">
                Withdrawals
              </h2>
              <button
                onClick={() => setShowWithdrawalsModal(false)}
                className="px-2 py-2 m-4 text-white bg-blue-500 ease-out duration-500 rounded-md font-bold text-sm hover:text-base "
              >
                Close
              </button>
            </div>
            <table className="w-full text-sm text-center text-gray-500">
              <thead className="text-sm text-gray-700 bg-gray-100">
                <tr>
                  <th className="px-4 py-4">No.</th>
                  <th className="px-4 py-4">Name</th>
                  <th className="px-4 py-4">Amount</th>
                  <th className="px-4 py-4">Created</th>
                  <th className="px-4 py-4">Updated</th>
                </tr>
              </thead>
              <tbody>
                {shopperWithdrawals.map((withdrawal, index) => (
                  <tr key={withdrawal.id}>
                    <td className="px-4 py-4">{index + 1}</td>
                    <td className="px-4 py-4">{withdrawal.name}</td>
                    <td className="px-4 py-4">
                      <span className="ml-1">&#8358;</span>{" "}
                      {formatNumberWithCommas(withdrawal.amount)}
                    </td>
                    <td className="px-4 py-4">
                      {formatCreatedAt(withdrawal.created_at)}
                    </td>
                    <td className="px-4 py-4">
                      {formatCreatedAt(withdrawal.updated_at)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {showRemittanceModal && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-12 rounded-lg max-w-6xl w-full max-h-[100vh] overflow-y-auto">
            <div className=" flex justify-between p-2">
              <h2 className="text-2xl font-semibold text-gray-500 mt-4 mb-4">
                Remittance
              </h2>
              <button
                onClick={() => setShowRemittanceModal(false)}
                className="px-2 py-2 m-4 text-white bg-blue-500 ease-out duration-500 rounded-md font-bold text-sm hover:text-base "
                >
                Close
              </button>
            </div>
            <table className="w-full text-sm text-center text-gray-500">
              <thead className="text-sm text-gray-700 bg-gray-100">
                <tr>
                  <th className="px-4 py-4">No.</th>
                  <th className="px-4 py-4">Name</th>
                  <th className="px-4 py-4">Amount</th>
                  <th className="px-4 py-4">Created</th>
                  <th className="px-4 py-4">Updated</th>
                </tr>
              </thead>
              <tbody>
                {shopperRemits.map((remittance, index) => (
                  <tr key={remittance.id}>
                    <td className="px-4 py-4">{index + 1}</td>
                    <td className="px-4 py-4">{remittance.name}</td>
                    <td className="px-4 py-4">
                      <span className="ml-1">&#8358;</span>{" "}
                      {formatNumberWithCommas(remittance.amount)}
                    </td>
                    <td className="px-4 py-4">
                      {formatCreatedAt(remittance.created_at)}
                    </td>
                    <td className="px-4 py-4">
                      {formatCreatedAt(remittance.updated_at)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopperCompletedPayment;
