import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { fetchDrivers } from "../../redux/slices/driverSlice";
import { useDispatch, useSelector } from "react-redux";
import { convertValue, formatCreatedAt } from "../../utils/commonUtils";
import { ImSpinner2 } from "react-icons/im";
import ModalVerifyDriver from "../../components/staffs/ModalVerifyDriver";
import ModalSuspendDriver from "../../components/staffs/ModalSuspendDriver";
import UpdateDriver from "../../components/modals/driver/UpdateDriver";
import ModalUnverifyDriver from "../../components/staffs/ModalUnverifyDriver";
import ModalUnsuspendDriver from "../../components/staffs/ModalUnsuspendDriver";
import ModalDeleteDriver from "../../components/staffs/ModalDeleteDriver";
import { fetchOrderReported } from "../../redux/slices/orderReportedSlice";

const DriverList = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDrivers());
    dispatch(fetchOrderReported());
  }, [dispatch]);

  const drivers = useSelector((state) => state.driver?.drivers);

  const orderReports = useSelector(
    (state) => state.orderReported?.orderReporteds
  );

  const getTotalReports = (id) => {
    return orderReports
      ? orderReports.filter((report) => +report.shopper_id === id).length
      : 0;
  };

  const reversedDrivers = drivers.slice().reverse();

  // Filter categories based on search term
  const filteredDrivers = reversedDrivers.filter((user) =>
    search.toLowerCase() === ""
      ? user
      : user.name.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentDrivers = filteredDrivers.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredDrivers.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.driver?.status);
  const error = useSelector((state) => state.driver?.error);

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Drivers ({drivers.length})
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">Drivers</p>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Drivers List
            </p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search driver..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-4 text-center">No.</th>

                <th className="px-4 py-4 text-left">Name</th>
                <th className="px-4 py-4 text-center">Image</th>

                <th className="px-4 py-4 text-center whitespace-nowrap">
                  Ref Code
                </th>

                <th className="px-4 py-4 text-center">Approved</th>
                <th className="px-4 py-4 text-center">Reported</th>
                <th className="px-4 py-4 text-center">Created</th>
                <th className="px-4 py-4 text-center">Last Updated</th>
                <th className="px-4 py-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm text-left">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentDrivers.length === 0 ? (
                <tr>
                  <td
                    colSpan="12"
                    className="text-center text-base text-gray-500 py-4"
                  >
                    No Data
                  </td>
                </tr>
              ) : (
                currentDrivers?.map((user, index) => (
                  <tr key={user.id}>
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex flex-col text-left gap-2">
                        <Link
                          to={`/dashboard/drivers/driver-detail/${user.id}`}
                        >
                          <p className=" text-blue-600 text-base font-semibold">
                            {user.name}
                          </p>
                        </Link>

                        <p className=" text-gray-500">{user.email}</p>
                        <p className=" font-bold">{user.phone}</p>
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex  justify-center items-center">
                        {user.passport ? (
                          <img
                            src={user.passport}
                            alt={user.name}
                            className=" rounded-xl w-20 h-20"
                          />
                        ) : (
                          "No Image"
                        )}
                      </div>
                    </td>

                    <td className="px-4 py-4 text-center">{user.ref_code}</td>
                    <td className="px-4 py-4 text-center">
                      {convertValue(user.approved ? user.approved : "Not yet")}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {getTotalReports(user.id)}
                    </td>
                    <td className="px-2 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(user.created_at)}
                    </td>
                    <td className="px-2 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(user.updated_at)}
                    </td>
                    <td className="px-2 py-2">
                      <div className="flex gap-4 justify-center items-center">
                        <div>
                          <UpdateDriver
                            user_id={user.id}
                            name={user.name}
                            email={user.email}
                            phone={user.phone}
                            state={user.state}
                            city={user.city}
                            country={user.country}
                          />
                        </div>
                        <div className=" flex justify-center">
                          {user.verified === "1" ? (
                            <ModalUnverifyDriver
                              id={user.id}
                              name={user.name}
                            />
                          ) : (
                            <ModalVerifyDriver id={user.id} name={user.name} />
                          )}
                        </div>
                        <div className=" flex justify-center">
                          {user.suspended === "1" ? (
                            <ModalUnsuspendDriver
                              id={user.id}
                              name={user.name}
                            />
                          ) : (
                            <ModalSuspendDriver id={user.id} name={user.name} />
                          )}
                        </div>
                        <div>
                          <ModalDeleteDriver
                            id={user.id}
                            name={user.name}
                            email={user.email}
                            phone={user.phone}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {drivers.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverList;
