import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";


import loginImg from "../../assets/login-bg2.png";
import BgLogo from "../../assets/bg-logo.png";
// import { verifyOTPAsync } from "../../redux/thunks/authThunks";
// import { setAccessToken } from "../../redux/slices/authSlice";

function OTP() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  // const dispatch = useDispatch();

 

  const [otp, setOTP] = useState(["", "", "", ""]);
  const refs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleChange = (index, e) => {
    const newOTP = [...otp];
    const digit = e.target.value.slice(-1);
    newOTP[index] = digit;
    setOTP(newOTP);

    if (e.target.value && index < refs.length - 1) {
      refs[index + 1].current.focus();
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text/plain");
    const digit = text.slice(0, 1);
    const newOTP = [...otp];
    newOTP[index] = digit;
    setOTP(newOTP);

    if (index < refs.length - 1) {
      refs[index + 1].current.focus();
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);

    // const token = otp.join("");

    try {
      // const result = await dispatch(verifyOTPAsync({ token, email })).unwrap();

    
      // const { data } = result;
      // const { accessToken } = data;

    
      // dispatch(setAccessToken(accessToken));

      // localStorage.getItem("accessToken", accessToken)
      //  console.log(localStorage);

      navigate("/dashboard");
    } catch (error) {
      // Log or handle the error appropriately
      console.error("Error during OTP verification:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 w-11/12 h-screen">
      <div className="hidden relative sm:block">
        <img className="w-full h-full object-cover" src={loginImg} alt="" />
        <div className=" absolute inset-0 flex items-end justify-center">
          <div className="flex flex-col m-4 p-8">
            <div className="flex justify-start p-4">
              <img src={BgLogo} alt="" className="w-52 " />
            </div>
            <h1 className=" text-left font-bold text-5xl text-white p-4">
              For all your laundry, shopping & investments solutions
            </h1>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col justify-center items-center min-h-screen overflow-hidden">
        <div className="w-full p-6 m-auto gap-8 bg-white rounded-md shadow-md lg:max-w-xl">
          <div className=" flex flex-col justify-center items-center gap-4">
            <h1 className=" text-2xl md:text-3xl font-semibold text-start text-gray-700">
              Complete Sign In
            </h1>

            <p className="mt-4">Enter OTP sent to confirm your identity</p>
          </div>
          <form
            onSubmit={handleOTPSubmit}
            className="flex flex-col mt-6 items-center"
          >
            <div className="flex justify-center">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  ref={refs[index]}
                  type="text"
                  maxLength={1}
                  value={digit || ""}
                  onChange={(e) => handleChange(index, e)}
                  onFocus={handleFocus}
                  onPaste={(e) => handlePaste(e, index)}
                  placeholder="0"
                  className="w-12 h-12 text-center mx-2 text-lg border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              ))}
            </div>
            {error && (
              <p className="text-red-500 text-sm mt-2">
                Incorrect OTP. Please try again.
              </p>
            )}
            <button
              type="submit"
              className="w-1/2 px-4 py-2 mt-8 text-white  transition-colors duration-200 transform bg-blue-700 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              {loading ? (
                <>
                  <div className="flex items-center justify-center gap-1">
                    <p>Verifying</p>
                    <FaSpinner className="animate-spin" />
                  </div>
                </>
              ) : (
                "Verify"
              )}
            </button>
          </form>
        </div>

        <div className="w-full mb-12">
          <p className=" text-gray-800 text-xs text-center md:text-start flex justify-center p-8">
            Protected by reCAPTCHA and subject to the Capfields Privacy Policy
            and Terms of Service.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OTP;
