import React, { useState } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BsExclamationLg } from "react-icons/bs";

import { MdOutlineDoNotDisturbOff } from "react-icons/md";

import {
  cancelOrder,
  fetchOrderPending,
} from "../../../redux/slices/orderPendingSlice";
import { pushNotifyUser } from "../../../redux/slices/pushSlice";

export default function CancelOrder({ orderId, name, id, user_id }) {
  const [showModal, setShowModal] = useState(false);

  const status = useSelector((state) => state.orderPending?.status);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      const res = await dispatch(cancelOrder(orderId));
      toast.success("Shopping order cancelled");
      // If cancellation was successful
      if (res.payload.status) {

        const pushNote = {
          user_id: [user_id,],
          order_id: orderId,
          title: "Capfields Evolve",
          body: `Your order with id #${orderId}, has been canceled. Thank you for shopping with us`,
          click: "SHOPPING",
        };
        dispatch(pushNotifyUser(pushNote));

        await dispatch(fetchOrderPending());

        setShowModal(false);
      }
    } catch (error) {
      // Handle cancellation error
      console.error("Error occurred while canceling order:", error);
    }
  };

  return (
    <>
      <div className=" ">
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="flex gap-2 items-center bg-white border-2 p-2 rounded-lg"
        >
          <MdOutlineDoNotDisturbOff className="text-red-600 text-lg font-extrabold" />
          <p className=" text-sm font-semibold">Cancel Order</p>
        </button>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center mb-4">
                  <h1 className="text-2xl text-gray-800 text-center font-medium">
                    Cancel Order
                  </h1>
                  <p>Do you want to Cancel this Order? </p>
                </div>
                <div className="flex justify-center items-center m-2">
                  <BsExclamationLg className="text-gray-500 text-3xl font-bold" />
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-2 justify-center items-center">
                      <h1 className="text-[14px] text-left text-gray-400 ">
                        name :
                      </h1>
                      <div className="">
                        <p className=" text-base font-bold">{name}</p>
                      </div>
                    </div>
                    <div className="text-center">
                      <p className=" text-base font-medium">{orderId}</p>
                    </div>

                    <div className="flex mt-2 gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-gray-500 font-semibold bg-gray-100 py-2 px-4 rounded-md"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-md items-center bg-red-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleSubmit}
                      >
                        {status === "loading" ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Cancelling ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaTimes className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Cancel Order
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
