import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPlus } from "react-icons/fa";
import { HiChevronRight } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addRole } from "../../redux/slices/rolesSlice";
import { CgSpinner } from "react-icons/cg";

const AddAdminRoles = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const initialFormData = {
    name: "",
    role: [""],
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const status = useSelector((state) => state.role?.status);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "name") {
      setFormData({
        ...formData,
        name: value,
      });
    } else {
      const newRoles = formData.role.map((role, i) =>
        i === index ? value : role
      );
      setFormData({
        ...formData,
        role: newRoles,
      });
    }
  };

  const handleAddRole = () => {
    setFormData({
      ...formData,
      role: [...formData.role, ""],
    });
  };

  // const handleRemoveRole = (indexToRemove) => {
  //   const updatedRoles = formData.role.filter(
  //     (role, index) => index !== indexToRemove
  //   );
  //   setFormData({
  //     ...formData,
  //     role: updatedRoles,
  //   });
  // };

  const isAddRoleButtonDisabled = formData.role[0] === "";
  const isSaveButtonDisabled =
    formData.name === "" || formData.role.some((role) => role === "");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(addRole(formData));

      setFormData({ ...initialFormData });
      await navigate("/dashboard/admin/admin-roles");
      toast.success(res.payload.message);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Create Admin Roles
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/admin/admin-roles">
              <p className="text-[14px] text-[#1F7BF4]  font-medium">
                Admin Role
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-gray-500 font-medium">
              Create Admin Roles
            </p>
          </div>
        </div>
        <div className=" flex items-end">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isSaveButtonDisabled}
            className={`flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium transition-all duration-200 ${
              isSaveButtonDisabled
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-[#1663cc]"
            }`}
          >
            {status === "loading" ? (
              <>
                <CgSpinner className="animate-spin text-lg text-gray-50" />
                Saving Admin Role...
              </>
            ) : (
              <>
                <FaPlus className=" text-lg font-extrabold" />
                Save Admin Role
              </>
            )}
          </button>
        </div>
      </div>
      <div className="flex flex-row  mt-6 gap-6">
        <div className="w-full h-auto">
          <form>
            <div className="w-full h-auto flex flex-col px-6 py-6 bg-white rounded-lg shadow gap-[14px]">
              <div className="flex flex-col  gap-4 ">
                <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
                  <label
                    htmlFor="name"
                    className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                  >
                    Name
                  </label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Type name here. . ."
                      className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    />
                  </div>
                </div>
                {/* {formData.role.map((role, index) => (
                  <div
                    key={index}
                    className="flex flex-col mt-4 gap-[14px] w-full h-auto px-4 py-4 bg-white rounded-lg shadow"
                  >
                    <label
                      htmlFor={`role-${index}`}
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      Role {index + 1}
                    </label>
                    <div className="flex items-center gap-4">
                      <input
                        type="text"
                        name={`role-${index}`}
                        value={role}
                        onChange={(e) => handleInputChange(e, index)}
                        placeholder="Type role here. . ."
                        className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      />
                      {index > 0 && (
                        <div className="border-2 border-red-500 p-2 flex justify-center items-center rounded-lg">
                          <FaTimes
                            className=" cursor-pointer text-red-500"
                            onClick={() => handleRemoveRole(index)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))} */}
                <button
                  type="button"
                  onClick={handleAddRole}
                  disabled={isAddRoleButtonDisabled}
                  className={`flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium mt-4 transition-all duration-200 ${
                    isAddRoleButtonDisabled
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-[#1663cc]"
                  }`}
                >
                  <FaPlus className="text-lg font-extrabold" />
                  Add More
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAdminRoles;
