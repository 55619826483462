import { useState } from "react";
import { TbLogout } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const LogoutModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleSignOut = () => {
    // Clear localStorage
    localStorage.clear();
    // Navigate to "/"
    navigate("/");
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="bg-white hover:bg-red-600 hover:text-white text-gray-500 text-base font-semibold py-2 px-4 rounded transition-colors duration-300"
      >
        <TbLogout className="text-2xl" />
      </button>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-8">
            <h2 className="text-lg font-semibold mb-4">
              Are you sure you want to Sign out?
            </h2>
            <div className="flex justify-center">
              <button
                onClick={() => setIsOpen(false)}
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleSignOut}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LogoutModal;
